﻿// Default Page Navigation Styles

.page-nav {
	display: block;
	overflow: visible;
	height: 27px;

	&__link {
		background-position: 0 90%;

		&:hover,
		&:focus {
			background-position: 0 90%;
		}

		&:focus,
		&:active,
		&:hover {

			.page-nav__icon-container {
				fill: #4175ac;
			}
		}
	}

	&__link--left {
		float: left;
	}

	&__link--right {
		float: right;
	}

	&__icon:after {
		bottom: 0%;
	}

	&__icon-container {
		fill: #125397;
	}

	&__link--disabled {
		background-image: linear-gradient(#c8c8c8, #c8c8c8);
		color: #c8c8c8;
		cursor: no-drop;

		.page-nav__icon-container {
			fill: #c8c8c8;
		}

		&:hover,
		&:active,
		&:focus {
			color: #c8c8c8;

			.page-nav__icon-container {
				fill: #c8c8c8;
			}
		}
	}
}
