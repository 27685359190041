* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  background: #f8f8f8;
  color: #000;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 100%;
  line-height: 1.5;
  position: relative;
  min-height: 100%;
}

body {
  height: 100%;
}

img {
  max-width: 100%;
  vertical-align: middle;
}

input,
textarea,
select,
button {
  color: inherit;
  font: inherit;
  margin: 0;
}

fieldset {
  border-style: none;
}

.layout__central-background {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.layout__central-background section:not(.portal-header) {
  flex-grow: 1;
}

input:focus,
textarea:focus,
select:focus,
.button:focus,
.form-element__label:focus,
.nav__collapse-button:focus,
label.is-active,
a:focus {
  outline: 3px solid #ffb500;
}

input[type=checkbox],
input[type=radio] {
  outline: 0;
}

label.is-selected {
  border: 1px solid #871a67;
}

.layout__container:after {
  clear: both;
}

.layout__container:after,
.layout__container:before {
  content: " ";
  display: table;
}

.layout__container--cookie-message {
  padding: 24px 0 16px;
}
.layout__container--cookie-message input[type=button] {
  margin-right: 16px;
}
@media (max-width: 425px) {
  .layout__container--cookie-message input[type=button] {
    margin-bottom: 16px;
  }
}

img.document_logo--devdemo {
  background-color: #fff;
  border: 3px solid #000;
  left: 0;
  padding: 2px;
  position: relative;
  top: 0;
}

.cfp-landing__heading {
  margin-top: 36px;
}
.cfp-landing__subheading {
  margin-top: 24px;
}
.cfp-landing .more-info {
  margin-top: 8px;
}

@media (min-width: 992px) {
  .cfp-page-content {
    padding-right: 10%;
  }
}
.cfp-page-content h1 {
  margin-bottom: 1.7em;
}
.cfp-page-content h2 {
  margin-top: 1.7em;
}
.cfp-page-content__doc-list h2 {
  margin-top: 1.7em;
}

.counter {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.counter__spacer {
  flex: 10 1 auto;
}
.counter__item {
  flex: 1 1 auto;
  text-align: right;
  padding: 0 2rem;
}

.document iframe.document__isolated-container {
  border: 0;
  width: 100%;
}

.header__banner {
  background-color: #007db9;
  margin: 0;
  overflow: hidden;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}
.header__brand {
  color: #fff;
  font-size: 1.5em;
  min-height: 1px;
  padding: 1.5rem 0;
  position: relative;
}
@media (min-width: 768px) {
  .header__brand {
    float: left;
    width: 41.66667%;
  }
}
@media (max-width: 991px) {
  .header__brand {
    display: inline-block;
    padding: 10px 0;
  }
}
.header__logo {
  display: block;
  float: none;
  margin: 0;
  max-width: 100%;
}
.header__content {
  color: #fff;
  min-height: 1px;
  padding: 1.5em 0;
  position: relative;
}
@media (min-width: 768px) {
  .header__content {
    float: left;
    width: 33.33333%;
  }
}
.header__widgets {
  clear: both;
  float: left;
  min-height: 1px;
  padding: 0 0 1.5em;
  position: relative;
  width: 100%;
}

.print-white .header {
  background: #fff;
}

.portal-header {
  background: #007db9;
  color: #fff;
}
.portal-header__container {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 768px) {
  .portal-header__container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .portal-header__container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .portal-header__container {
    width: 1170px;
  }
}
.portal-header__container--content {
  font-size: 1.2em;
  min-height: 1px;
  padding: 1.2rem 0;
}

.layout__container--footer {
  border-top: 1px solid #eee;
}
@media (min-width: 768px) {
  .layout__container--footer {
    padding: 5px 0 0;
  }
}

.footer {
  clear: both;
  font-size: 0.875em;
  margin: 100px 0 0;
  min-height: 130px;
  overflow: hidden;
}
.footer--scope a:hover {
  background-image: none;
}
.footer__content-one {
  float: left;
  min-height: 1px;
  position: relative;
  text-align: left;
  width: 100%;
}
@media (min-width: 768px) {
  .footer__content-one {
    float: left;
    padding: 20px 20px 20px 0;
    width: 25%;
  }
}
@media (min-width: 1200px) {
  .footer__content-one {
    padding: 10px 0;
  }
}
.footer__content-two {
  float: left;
  min-height: 1px;
  position: relative;
  width: 100%;
}
@media (min-width: 768px) {
  .footer__content-two {
    float: left;
  }
}
.footer__content-two--scope p {
  margin: 5px 0;
}
.footer__content-three {
  float: left;
  min-height: 1px;
  position: relative;
  width: 100%;
}
@media (min-width: 768px) {
  .footer__content-three {
    float: left;
    width: 25%;
  }
}

p {
  font-size: 1em;
  margin: 12px 0 0;
  line-height: 1.5;
}
@media (min-width: 992px) {
  p {
    font-size: 1.125em;
  }
}

*[data-bind*="customWordingHtml:"] p {
  font-size: 1em;
}

input,
select.input,
label,
td,
th,
textarea {
  font-size: 1em;
}
@media (min-width: 992px) {
  input,
select.input,
label,
td,
th,
textarea {
    font-size: 1.125rem;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.2em;
}

h1 {
  font-size: 2em;
  margin-top: 0.75rem;
}
@media (min-width: 992px) {
  h1 {
    font-size: 2.25em;
  }
}

h2 {
  font-size: 1.5em;
  margin-top: 1.5rem;
}
@media (min-width: 992px) {
  h2 {
    font-size: 1.75em;
  }
}
h2.h2-smaller {
  font-size: 1.375em;
  margin-top: 2.25rem;
}
@media (min-width: 992px) {
  h2.h2-smaller {
    font-size: 1.5em;
  }
}

h3 {
  font-size: 1.375em;
  margin-top: 2.25rem;
}
@media (min-width: 992px) {
  h3 {
    font-size: 1.5em;
  }
}

h4 {
  font-size: 1.25em;
  margin-top: 1.5rem;
}

h5 {
  font-size: 1.125em;
  margin-top: 1.5rem;
}

h6 {
  font-size: 1rem;
  margin-top: 1.5em;
}

a {
  color: #125397;
  cursor: pointer;
  font-size: 1.125em;
  text-decoration: underline;
}
@media (max-width: 991px) {
  a {
    font-size: 1em;
  }
}
a:active, a:hover, a:focus {
  background-image: none;
  color: #4175ac;
  text-decoration: underline;
}
a::selection {
  background: #e0e0e0;
  text-shadow: none;
}

a.disabled {
  pointer-events: none;
  cursor: default;
  text-decoration: none;
  color: #3f3f3f !important;
}

p a,
span {
  font-size: inherit;
}

.u-text-weight-regular p {
  font-size: inherit;
}

.layout__controls {
  margin-top: 48px;
}
.layout__controls--info-content {
  margin-top: 24px;
}

.layout__container {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 768px) {
  .layout__container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .layout__container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .layout__container {
    width: 1170px;
  }
}
.layout__container--fluid {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}
.layout__container--cookie-message {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 768px) {
  .layout__container--cookie-message {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .layout__container--cookie-message {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .layout__container--cookie-message {
    width: 1170px;
  }
}

.layout__row {
  margin-left: -15px;
  margin-right: -15px;
}
.layout__row:after {
  clear: both;
  content: "";
  display: table;
}

.layout__row-flex {
  display: flex;
  flex-wrap: wrap;
}

.layout__col--xs-1,
.layout__col--sm-1,
.layout__col--md-1,
.layout__col--lg-1,
.layout__col--xs-2,
.layout__col--sm-2,
.layout__col--md-2,
.layout__col--lg-2,
.layout__col--xs-3,
.layout__col--sm-3,
.layout__col--md-3,
.layout__col--lg-3,
.layout__col--xs-4,
.layout__col--sm-4,
.layout__col--md-4,
.layout__col--lg-4,
.layout__col--xs-5,
.layout__col--sm-5,
.layout__col--md-5,
.layout__col--lg-5,
.layout__col--xs-6,
.layout__col--sm-6,
.layout__col--md-6,
.layout__col--lg-6,
.layout__col--xs-7,
.layout__col--sm-7,
.layout__col--md-7,
.layout__col--lg-7,
.layout__col--xs-8,
.layout__col--sm-8,
.layout__col--md-8,
.layout__col--lg-8,
.layout__col--xs-9,
.layout__col--sm-9,
.layout__col--md-9,
.layout__col--lg-9,
.layout__col--xs-10,
.layout__col--sm-10,
.layout__col--md-10,
.layout__col--lg-10,
.layout__col--xs-11,
.layout__col--sm-11,
.layout__col--md-11,
.layout__col--lg-11,
.layout__col--xs-12,
.layout__col--sm-12,
.layout__col--md-12,
.layout__col--lg-12 {
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
}
.layout__col--xs-1:after,
.layout__col--sm-1:after,
.layout__col--md-1:after,
.layout__col--lg-1:after,
.layout__col--xs-2:after,
.layout__col--sm-2:after,
.layout__col--md-2:after,
.layout__col--lg-2:after,
.layout__col--xs-3:after,
.layout__col--sm-3:after,
.layout__col--md-3:after,
.layout__col--lg-3:after,
.layout__col--xs-4:after,
.layout__col--sm-4:after,
.layout__col--md-4:after,
.layout__col--lg-4:after,
.layout__col--xs-5:after,
.layout__col--sm-5:after,
.layout__col--md-5:after,
.layout__col--lg-5:after,
.layout__col--xs-6:after,
.layout__col--sm-6:after,
.layout__col--md-6:after,
.layout__col--lg-6:after,
.layout__col--xs-7:after,
.layout__col--sm-7:after,
.layout__col--md-7:after,
.layout__col--lg-7:after,
.layout__col--xs-8:after,
.layout__col--sm-8:after,
.layout__col--md-8:after,
.layout__col--lg-8:after,
.layout__col--xs-9:after,
.layout__col--sm-9:after,
.layout__col--md-9:after,
.layout__col--lg-9:after,
.layout__col--xs-10:after,
.layout__col--sm-10:after,
.layout__col--md-10:after,
.layout__col--lg-10:after,
.layout__col--xs-11:after,
.layout__col--sm-11:after,
.layout__col--md-11:after,
.layout__col--lg-11:after,
.layout__col--xs-12:after,
.layout__col--sm-12:after,
.layout__col--md-12:after,
.layout__col--lg-12:after {
  content: "";
  clear: both;
  display: table;
}

.layout__col--xs-1,
.layout__col--xs-2,
.layout__col--xs-3,
.layout__col--xs-4,
.layout__col--xs-5,
.layout__col--xs-6,
.layout__col--xs-7,
.layout__col--xs-8,
.layout__col--xs-9,
.layout__col--xs-10,
.layout__col--xs-11,
.layout__col--xs-12 {
  float: left;
}

.layout__col--xs-1 {
  width: 8.33333%;
}

.layout__col--xs-2 {
  width: 16.66667%;
}

.layout__col--xs-3 {
  width: 25%;
}

.layout__col--xs-4 {
  width: 33.33333%;
}

.layout__col--xs-5 {
  width: 41.66667%;
}

.layout__col--xs-6 {
  width: 50%;
}

.layout__col--xs-7 {
  width: 58.33333%;
}

.layout__col--xs-8 {
  width: 66.66667%;
}

.layout__col--xs-9 {
  width: 75%;
}

.layout__col--xs-10 {
  width: 83.33333%;
}

.layout__col--xs-11 {
  width: 91.66667%;
}

.layout__col--xs-12 {
  width: 100%;
}

.layout__col--xs-pull-0 {
  right: auto;
}

.layout__col--xs-pull-1 {
  right: 8.33333%;
}

.layout__col--xs-pull-2 {
  right: 16.66667%;
}

.layout__col--xs-pull-3 {
  right: 25%;
}

.layout__col--xs-pull-4 {
  right: 33.33333%;
}

.layout__col--xs-pull-5 {
  right: 41.66667%;
}

.layout__col--xs-pull-6 {
  right: 50%;
}

.layout__col--xs-pull-7 {
  right: 58.33333%;
}

.layout__col--xs-pull-8 {
  right: 66.66667%;
}

.layout__col--xs-pull-9 {
  right: 75%;
}

.layout__col--xs-pull-10 {
  right: 83.33333%;
}

.layout__col--xs-pull-11 {
  right: 91.66667%;
}

.layout__col--xs-pull-12 {
  right: 100%;
}

.layout__col--xs-push-0 {
  left: auto;
}

.layout__col--xs-push-1 {
  left: 8.33333%;
}

.layout__col--xs-push-2 {
  left: 16.66667%;
}

.layout__col--xs-push-3 {
  left: 25%;
}

.layout__col--xs-push-4 {
  left: 33.33333%;
}

.layout__col--xs-push-5 {
  left: 41.66667%;
}

.layout__col--xs-push-6 {
  left: 50%;
}

.layout__col--xs-push-7 {
  left: 58.33333%;
}

.layout__col--xs-push-8 {
  left: 66.66667%;
}

.layout__col--xs-push-9 {
  left: 75%;
}

.layout__col--xs-push-10 {
  left: 83.33333%;
}

.layout__col--xs-push-11 {
  left: 91.66667%;
}

.layout__col--xs-push-12 {
  left: 100%;
}

.layout__col--xs-offset-0 {
  margin-left: 0%;
}

.layout__col--xs-offset-1 {
  margin-left: 8.33333%;
}

.layout__col--xs-offset-2 {
  margin-left: 16.66667%;
}

.layout__col--xs-offset-3 {
  margin-left: 25%;
}

.layout__col--xs-offset-4 {
  margin-left: 33.33333%;
}

.layout__col--xs-offset-5 {
  margin-left: 41.66667%;
}

.layout__col--xs-offset-6 {
  margin-left: 50%;
}

.layout__col--xs-offset-7 {
  margin-left: 58.33333%;
}

.layout__col--xs-offset-8 {
  margin-left: 66.66667%;
}

.layout__col--xs-offset-9 {
  margin-left: 75%;
}

.layout__col--xs-offset-10 {
  margin-left: 83.33333%;
}

.layout__col--xs-offset-11 {
  margin-left: 91.66667%;
}

.layout__col--xs-offset-12 {
  margin-left: 100%;
}

@media (min-width: 768px) {
  .layout__col--sm-1,
.layout__col--sm-2,
.layout__col--sm-3,
.layout__col--sm-4,
.layout__col--sm-5,
.layout__col--sm-6,
.layout__col--sm-7,
.layout__col--sm-8,
.layout__col--sm-9,
.layout__col--sm-10,
.layout__col--sm-11,
.layout__col--sm-12 {
    float: left;
  }
  .layout__col--sm-1 {
    width: 8.33333%;
  }
  .layout__col--sm-2 {
    width: 16.66667%;
  }
  .layout__col--sm-3 {
    width: 25%;
  }
  .layout__col--sm-4 {
    width: 33.33333%;
  }
  .layout__col--sm-5 {
    width: 41.66667%;
  }
  .layout__col--sm-6 {
    width: 50%;
  }
  .layout__col--sm-7 {
    width: 58.33333%;
  }
  .layout__col--sm-8 {
    width: 66.66667%;
  }
  .layout__col--sm-9 {
    width: 75%;
  }
  .layout__col--sm-10 {
    width: 83.33333%;
  }
  .layout__col--sm-11 {
    width: 91.66667%;
  }
  .layout__col--sm-12 {
    width: 100%;
  }
  .layout__col--sm-pull-0 {
    right: auto;
  }
  .layout__col--sm-pull-1 {
    right: 8.33333%;
  }
  .layout__col--sm-pull-2 {
    right: 16.66667%;
  }
  .layout__col--sm-pull-3 {
    right: 25%;
  }
  .layout__col--sm-pull-4 {
    right: 33.33333%;
  }
  .layout__col--sm-pull-5 {
    right: 41.66667%;
  }
  .layout__col--sm-pull-6 {
    right: 50%;
  }
  .layout__col--sm-pull-7 {
    right: 58.33333%;
  }
  .layout__col--sm-pull-8 {
    right: 66.66667%;
  }
  .layout__col--sm-pull-9 {
    right: 75%;
  }
  .layout__col--sm-pull-10 {
    right: 83.33333%;
  }
  .layout__col--sm-pull-11 {
    right: 91.66667%;
  }
  .layout__col--sm-pull-12 {
    right: 100%;
  }
  .layout__col--sm-push-0 {
    left: auto;
  }
  .layout__col--sm-push-1 {
    left: 8.33333%;
  }
  .layout__col--sm-push-2 {
    left: 16.66667%;
  }
  .layout__col--sm-push-3 {
    left: 25%;
  }
  .layout__col--sm-push-4 {
    left: 33.33333%;
  }
  .layout__col--sm-push-5 {
    left: 41.66667%;
  }
  .layout__col--sm-push-6 {
    left: 50%;
  }
  .layout__col--sm-push-7 {
    left: 58.33333%;
  }
  .layout__col--sm-push-8 {
    left: 66.66667%;
  }
  .layout__col--sm-push-9 {
    left: 75%;
  }
  .layout__col--sm-push-10 {
    left: 83.33333%;
  }
  .layout__col--sm-push-11 {
    left: 91.66667%;
  }
  .layout__col--sm-push-12 {
    left: 100%;
  }
  .layout__col--sm-offset-0 {
    margin-left: 0%;
  }
  .layout__col--sm-offset-1 {
    margin-left: 8.33333%;
  }
  .layout__col--sm-offset-2 {
    margin-left: 16.66667%;
  }
  .layout__col--sm-offset-3 {
    margin-left: 25%;
  }
  .layout__col--sm-offset-4 {
    margin-left: 33.33333%;
  }
  .layout__col--sm-offset-5 {
    margin-left: 41.66667%;
  }
  .layout__col--sm-offset-6 {
    margin-left: 50%;
  }
  .layout__col--sm-offset-7 {
    margin-left: 58.33333%;
  }
  .layout__col--sm-offset-8 {
    margin-left: 66.66667%;
  }
  .layout__col--sm-offset-9 {
    margin-left: 75%;
  }
  .layout__col--sm-offset-10 {
    margin-left: 83.33333%;
  }
  .layout__col--sm-offset-11 {
    margin-left: 91.66667%;
  }
  .layout__col--sm-offset-12 {
    margin-left: 100%;
  }
}
@media (min-width: 992px) {
  .layout__col--md-1,
.layout__col--md-2,
.layout__col--md-3,
.layout__col--md-4,
.layout__col--md-5,
.layout__col--md-6,
.layout__col--md-7,
.layout__col--md-8,
.layout__col--md-9,
.layout__col--md-10,
.layout__col--md-11,
.layout__col--md-12 {
    float: left;
  }
  .layout__col--md-1 {
    width: 8.33333%;
  }
  .layout__col--md-2 {
    width: 16.66667%;
  }
  .layout__col--md-3 {
    width: 25%;
  }
  .layout__col--md-4 {
    width: 33.33333%;
  }
  .layout__col--md-5 {
    width: 41.66667%;
  }
  .layout__col--md-6 {
    width: 50%;
  }
  .layout__col--md-7 {
    width: 58.33333%;
  }
  .layout__col--md-8 {
    width: 66.66667%;
  }
  .layout__col--md-9 {
    width: 75%;
  }
  .layout__col--md-10 {
    width: 83.33333%;
  }
  .layout__col--md-11 {
    width: 91.66667%;
  }
  .layout__col--md-12 {
    width: 100%;
  }
  .layout__col--md-pull-0 {
    right: auto;
  }
  .layout__col--md-pull-1 {
    right: 8.33333%;
  }
  .layout__col--md-pull-2 {
    right: 16.66667%;
  }
  .layout__col--md-pull-3 {
    right: 25%;
  }
  .layout__col--md-pull-4 {
    right: 33.33333%;
  }
  .layout__col--md-pull-5 {
    right: 41.66667%;
  }
  .layout__col--md-pull-6 {
    right: 50%;
  }
  .layout__col--md-pull-7 {
    right: 58.33333%;
  }
  .layout__col--md-pull-8 {
    right: 66.66667%;
  }
  .layout__col--md-pull-9 {
    right: 75%;
  }
  .layout__col--md-pull-10 {
    right: 83.33333%;
  }
  .layout__col--md-pull-11 {
    right: 91.66667%;
  }
  .layout__col--md-pull-12 {
    right: 100%;
  }
  .layout__col--md-push-0 {
    left: auto;
  }
  .layout__col--md-push-1 {
    left: 8.33333%;
  }
  .layout__col--md-push-2 {
    left: 16.66667%;
  }
  .layout__col--md-push-3 {
    left: 25%;
  }
  .layout__col--md-push-4 {
    left: 33.33333%;
  }
  .layout__col--md-push-5 {
    left: 41.66667%;
  }
  .layout__col--md-push-6 {
    left: 50%;
  }
  .layout__col--md-push-7 {
    left: 58.33333%;
  }
  .layout__col--md-push-8 {
    left: 66.66667%;
  }
  .layout__col--md-push-9 {
    left: 75%;
  }
  .layout__col--md-push-10 {
    left: 83.33333%;
  }
  .layout__col--md-push-11 {
    left: 91.66667%;
  }
  .layout__col--md-push-12 {
    left: 100%;
  }
  .layout__col--md-offset-0 {
    margin-left: 0%;
  }
  .layout__col--md-offset-1 {
    margin-left: 8.33333%;
  }
  .layout__col--md-offset-2 {
    margin-left: 16.66667%;
  }
  .layout__col--md-offset-3 {
    margin-left: 25%;
  }
  .layout__col--md-offset-4 {
    margin-left: 33.33333%;
  }
  .layout__col--md-offset-5 {
    margin-left: 41.66667%;
  }
  .layout__col--md-offset-6 {
    margin-left: 50%;
  }
  .layout__col--md-offset-7 {
    margin-left: 58.33333%;
  }
  .layout__col--md-offset-8 {
    margin-left: 66.66667%;
  }
  .layout__col--md-offset-9 {
    margin-left: 75%;
  }
  .layout__col--md-offset-10 {
    margin-left: 83.33333%;
  }
  .layout__col--md-offset-11 {
    margin-left: 91.66667%;
  }
  .layout__col--md-offset-12 {
    margin-left: 100%;
  }
}
@media (min-width: 1200px) {
  .layout__col--lg-1,
.layout__col--lg-2,
.layout__col--lg-3,
.layout__col--lg-4,
.layout__col--lg-5,
.layout__col--lg-6,
.layout__col--lg-7,
.layout__col--lg-8,
.layout__col--lg-9,
.layout__col--lg-10,
.layout__col--lg-11,
.layout__col--lg-12 {
    float: left;
  }
  .layout__col--lg-1 {
    width: 8.33333%;
  }
  .layout__col--lg-2 {
    width: 16.66667%;
  }
  .layout__col--lg-3 {
    width: 25%;
  }
  .layout__col--lg-4 {
    width: 33.33333%;
  }
  .layout__col--lg-5 {
    width: 41.66667%;
  }
  .layout__col--lg-6 {
    width: 50%;
  }
  .layout__col--lg-7 {
    width: 58.33333%;
  }
  .layout__col--lg-8 {
    width: 66.66667%;
  }
  .layout__col--lg-9 {
    width: 75%;
  }
  .layout__col--lg-10 {
    width: 83.33333%;
  }
  .layout__col--lg-11 {
    width: 91.66667%;
  }
  .layout__col--lg-12 {
    width: 100%;
  }
  .layout__col--lg-pull-0 {
    right: auto;
  }
  .layout__col--lg-pull-1 {
    right: 8.33333%;
  }
  .layout__col--lg-pull-2 {
    right: 16.66667%;
  }
  .layout__col--lg-pull-3 {
    right: 25%;
  }
  .layout__col--lg-pull-4 {
    right: 33.33333%;
  }
  .layout__col--lg-pull-5 {
    right: 41.66667%;
  }
  .layout__col--lg-pull-6 {
    right: 50%;
  }
  .layout__col--lg-pull-7 {
    right: 58.33333%;
  }
  .layout__col--lg-pull-8 {
    right: 66.66667%;
  }
  .layout__col--lg-pull-9 {
    right: 75%;
  }
  .layout__col--lg-pull-10 {
    right: 83.33333%;
  }
  .layout__col--lg-pull-11 {
    right: 91.66667%;
  }
  .layout__col--lg-pull-12 {
    right: 100%;
  }
  .layout__col--lg-push-0 {
    left: auto;
  }
  .layout__col--lg-push-1 {
    left: 8.33333%;
  }
  .layout__col--lg-push-2 {
    left: 16.66667%;
  }
  .layout__col--lg-push-3 {
    left: 25%;
  }
  .layout__col--lg-push-4 {
    left: 33.33333%;
  }
  .layout__col--lg-push-5 {
    left: 41.66667%;
  }
  .layout__col--lg-push-6 {
    left: 50%;
  }
  .layout__col--lg-push-7 {
    left: 58.33333%;
  }
  .layout__col--lg-push-8 {
    left: 66.66667%;
  }
  .layout__col--lg-push-9 {
    left: 75%;
  }
  .layout__col--lg-push-10 {
    left: 83.33333%;
  }
  .layout__col--lg-push-11 {
    left: 91.66667%;
  }
  .layout__col--lg-push-12 {
    left: 100%;
  }
  .layout__col--lg-offset-0 {
    margin-left: 0%;
  }
  .layout__col--lg-offset-1 {
    margin-left: 8.33333%;
  }
  .layout__col--lg-offset-2 {
    margin-left: 16.66667%;
  }
  .layout__col--lg-offset-3 {
    margin-left: 25%;
  }
  .layout__col--lg-offset-4 {
    margin-left: 33.33333%;
  }
  .layout__col--lg-offset-5 {
    margin-left: 41.66667%;
  }
  .layout__col--lg-offset-6 {
    margin-left: 50%;
  }
  .layout__col--lg-offset-7 {
    margin-left: 58.33333%;
  }
  .layout__col--lg-offset-8 {
    margin-left: 66.66667%;
  }
  .layout__col--lg-offset-9 {
    margin-left: 75%;
  }
  .layout__col--lg-offset-10 {
    margin-left: 83.33333%;
  }
  .layout__col--lg-offset-11 {
    margin-left: 91.66667%;
  }
  .layout__col--lg-offset-12 {
    margin-left: 100%;
  }
}
/* Print styling - makes cols print as md or lg percentages instead of xs size which is does as default */
@media print {
  .layout__col--print {
    float: left;
  }
  .layout__col--print.layout__col--md-8,
.layout__col--print.layout__col--lg-8 {
    width: 66.66666667%;
  }
  .layout__col--print.layout__col--lg-7,
.layout__col--print.layout__col--md-7 {
    width: 58.33333333%;
  }
  .layout__col--print.layout__col--lg-6,
.layout__col--print.layout__col--md-6 {
    width: 50%;
  }
  .layout__col--print.layout__col--md-4.layout__col--lg-push-1,
.layout__col--print.layout__col--lg-5,
.layout__col--print.layout__col--md-5 {
    width: 41.66666667%;
  }
  .layout__col--print.layout__col--md-4,
.layout__col--print.layout__col--md-4 {
    width: 33.33333333%;
  }
  .layout__col--print.layout__col--md-3,
.layout__col--print.layout__col--lg-3 {
    width: 25%;
  }
  .layout__col--print.layout__col--md-2,
.layout__col--print.layout__col--lg-2 {
    width: 16.66666667%;
  }
  .layout__col--print.layout__col--lg-1,
.layout__col--print.layout__col--md-1 {
    width: 8.33333333%;
  }
}
@media (min-width: 768px) {
  .layout__flex {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .layout__flex--item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
  }
  .layout__flex--item a {
    width: 48%;
  }
}
@media print {
  body {
    background: #fff;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}
.print-together {
  break-inside: avoid-page;
}

.alert {
  background: #fff;
  border: 3px solid #333;
  display: none;
  left: 25%;
  padding: 20px;
  position: fixed;
  top: 4%;
  z-index: 9999999;
}

.button {
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  font-size: 1em;
  font-weight: 400;
  margin: 0;
  padding: 0.75rem 1.125rem;
  position: relative;
  text-decoration: none;
  text-shadow: none;
}
@media (min-width: 992px) {
  .button {
    font-size: 1.125em;
  }
}
.button__icon {
  padding-left: 0.5em;
}
.button__icon__container {
  width: 24px;
  height: 24px;
  vertical-align: sub;
}
.button.active, .button:active, .button:hover {
  background-image: none;
}
.button.active:after, .button:active:after, .button:hover:after {
  display: none;
}
.button:first-child {
  margin-left: 0;
}
.button--no-margin {
  margin-left: 0;
  margin-right: 0;
}
.button--action {
  background-color: #007db9;
  color: #fff;
  border: 1px solid #007db9;
}
.button--action:active, .button--action:hover, .button--action:focus {
  background-color: #005b86;
  color: #fff;
  border: 1px solid #005b86;
}
.button--secondary {
  background: none;
  color: #000;
  border: 1px solid #007db9;
}
.button--secondary:active, .button--secondary:hover, .button--secondary:focus {
  background-color: #005b86;
  color: #fff;
  border: 1px solid #005b86;
}
.button__cookie-banner {
  color: #fff;
}
.button--small {
  font-size: 1.125rem;
}

.button:disabled,
.button.is-disabled,
.button[disabled],
fieldset[disabled] .button {
  cursor: not-allowed;
  background: #d4d4d4;
}

@media (max-width: 500px) {
  .layout__button-group {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
  }
  .layout__button-group a {
    margin-right: 0;
    margin-top: 0;
  }
}
@media (max-width: 992px) {
  .layout__button-group {
    margin-top: 2em;
  }
}
.layout__button-group button,
.layout__button-group a {
  margin-bottom: 20px;
  margin-right: 30px;
  margin-top: 3em;
}
@media (max-width: 992px) {
  .layout__button-group button,
.layout__button-group a {
    margin-top: 0;
    margin-right: 10px;
  }
}
@media (max-width: 500px) {
  .layout__button-group button,
.layout__button-group a {
    width: 100%;
    text-align: center;
  }
}
.layout__button-group button:last-child,
.layout__button-group a:last-child {
  margin-right: 0;
}

a.button, a.button:hover, a.button:focus, a.button:active {
  text-decoration: none;
}

.breadcrumbs {
  color: #007db9;
  display: block;
  margin: 24px 0 0;
}
.breadcrumbs a {
  text-decoration: underline;
}

ul,
ol {
  list-style: none;
  margin: 0;
}

ul ul,
ul ol,
ol ul,
ol ol {
  margin-left: 12px;
}

li > ul,
li > ol {
  list-style: none;
  margin-bottom: 0;
}

/**
 * Visually offset definition titles from their definitions.
 */
dt {
  font-weight: 700;
}

.list {
  margin-top: 12px;
  margin-bottom: 24px;
  padding-left: 20px;
}
.list__item {
  margin-top: 6px;
  list-style: disc;
}
.list--bottom-margin-zero {
  margin-top: 12px;
  margin-bottom: 0;
}

.nav {
  display: inline-block;
}
.nav__collapse-button {
  background: none;
  background-image: none;
  text-decoration: none;
  text-shadow: none;
}
@media (max-width: 768px) {
  .nav__collapse-button {
    border: 0;
    display: block;
  }
}
@media (min-width: 768px) {
  .nav__collapse-button {
    display: none;
  }
}
.nav__collapse-button-title {
  color: #fff;
  display: block;
  float: left;
  font-size: 1.11111em;
  line-height: 35px;
  text-transform: uppercase;
}
.nav__cb-layers-container {
  border: 3px solid #fff;
  border-radius: 3px;
  display: block;
  height: 35px;
  margin-left: 65px;
  width: 35px;
}
.nav__cb-layers-container--xl {
  margin-left: 70px;
}
.nav__cb-layers-container--xll {
  margin-left: 74px;
}
.nav__cb-layer {
  background: #fff;
  display: block;
  height: 3px;
  margin: 5px;
}
.nav__colapse-container {
  display: block;
}
@media (max-width: 768px) {
  .nav__colapse-container {
    display: none;
  }
}
@media (min-width: 768px) {
  .nav__colapse-container {
    display: block;
  }
}
.nav__colapse-container.is-collapsed {
  display: block;
}
.nav--header {
  min-height: 1px;
  padding: 24px 0;
  position: relative;
}
@media (min-width: 768px) {
  .nav--header {
    float: left;
    width: 25%;
  }
}
@media (max-width: 767px) {
  .nav--header {
    float: right;
    padding: 10px 0;
  }
}
@media (min-width: 768px) {
  .nav--header {
    left: 33.33333%;
  }
}
@media (min-width: 767px) {
  .nav--header .nav__list-items {
    float: right;
  }
}
@media (min-width: 768px) {
  .nav--header .nav__item {
    margin: 0 0 0 20px;
  }
}
@media (min-width: 768px) {
  .nav--header .nav__item {
    float: left;
  }
}
.nav--header .nav__link {
  background-image: none;
  color: #fff;
  font-size: 0.875em;
  text-decoration: none;
  text-shadow: none;
}
@media (max-width: 991px) {
  .nav--header .nav__link {
    display: inline-table;
  }
}
.nav--header .nav__link:hover {
  color: #fff;
  background-image: linear-gradient(#fff, #fff);
  background-position: 0 1.15em;
  background-size: 1px 1px;
  background-repeat: repeat-x;
}
.nav--footer {
  float: left;
  min-height: 1px;
  padding: 10px 0;
  position: relative;
  width: 100%;
}
@media (min-width: 768px) {
  .nav--footer {
    float: left;
    width: 75%;
  }
}
.nav--footer .nav__item {
  display: inline-block;
  margin-right: 31px;
}
.nav--footer .nav__item:last-child {
  margin-right: 0;
}
.nav--footer .nav__link {
  background-image: none;
  position: relative;
  text-decoration: none;
  text-shadow: none;
}
.nav--footer .nav__link:active, .nav--footer .nav__link:focus, .nav--footer .nav__link:hover {
  text-decoration: underline;
}

.accessibility__skip-menu {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.accessibility__skip-menu-link {
  left: -9999px;
  position: absolute;
}
.accessibility__skip-menu-link:active, .accessibility__skip-menu-link:focus {
  background: #ffb500;
  color: #000;
  display: block;
  left: 0;
  padding: 0.25em 1em;
  position: static;
  text-shadow: none;
}

.accessibility__item {
  margin-left: 111px;
  margin: 0;
  position: absolute;
  z-index: 1;
}

.accessibility__page-nav-text {
  left: -9999px;
  position: absolute;
}

.accessibility-text-size {
  color: #fff;
  display: inline-block;
  float: right;
  margin-right: 0.625em;
}
@media (min-width: 750px) {
  .accessibility-text-size {
    margin-top: -2px;
  }
}
.accessibility-text-size__content {
  font-size: 12px;
  vertical-align: middle;
}
.accessibility-text-size__link {
  background-color: #000;
  color: #fff;
  cursor: pointer;
  line-height: 15px;
  padding: 0 4px;
  vertical-align: middle;
  text-align: center;
  text-shadow: none;
}
.accessibility-text-size__link--small {
  font-size: 12px;
  line-height: inherit;
  padding: 4px 6px;
}
.accessibility-text-size__link--medium {
  font-size: 14px;
  line-height: inherit;
  padding: 3px 6px;
}
.accessibility-text-size__link--large {
  font-size: 15px;
  line-height: inherit;
  padding: 3px 6px;
}

.timeline {
  font-size: 1em;
  padding-top: 10px;
  padding-bottom: 15px;
}
.timeline__link {
  background-image: linear-gradient(#125397, #125397);
  color: #125397;
  margin-right: 6px;
}
.timeline__link:first-of-type {
  margin-right: 12px;
}
.timeline__list, .timeline__item {
  display: inline-block;
}
.timeline__list {
  border-top: 1px solid #000;
  margin-left: 10px;
}
@media (max-width: 1200px) {
  .timeline__list {
    border-top: 0;
  }
}
.timeline__item {
  margin-left: 6px;
  padding: 0;
}
@media (max-width: 1200px) {
  .timeline__item {
    display: none;
  }
}
.timeline__item:first-of-type {
  margin-left: 0;
}
.timeline__item:last-of-type .timeline__nav-link {
  margin-right: -1px;
}
.timeline__nav-link {
  background: none;
  color: #d3d3d3;
  display: block;
  line-height: initial;
  margin-right: 6px;
  padding-top: 5px;
  position: relative;
  text-shadow: none;
}

@media (max-width: 1200px) {
  .is-active.timeline__item {
    display: block;
  }
}

.is-visited .timeline__nav-link {
  color: #000;
}

.timeline__nav-link:active,
.timeline__nav-link:hover,
.is-active .timeline__nav-link {
  color: #125397;
}
.timeline__nav-link:active:before,
.timeline__nav-link:hover:before,
.is-active .timeline__nav-link:before {
  background: #125397;
  content: "";
  display: block;
  height: 3px;
  position: absolute;
  top: 0;
  width: 100%;
}
@media (max-width: 1200px) {
  .timeline__nav-link:active:before,
.timeline__nav-link:hover:before,
.is-active .timeline__nav-link:before {
    display: none;
  }
}

.form-element {
  display: block;
  margin-top: 0.375em;
}
.form-element--inline {
  display: inline-block;
  margin-top: 0;
  background: transparent;
}
.form-element--single, .form-element--data-short, .form-element--data-long {
  padding-left: 0;
}
.form-element--single .form-element__sign, .form-element--data-short .form-element__sign, .form-element--data-long .form-element__sign {
  margin-left: 0;
}
.form-element--data-long .form-element__input, .form-element--data-short .form-element__input {
  width: 100%;
}
.form-element--data-short .form-element__input {
  margin: 0 0.75em 0 0;
  max-width: 52px;
}
.form-element--data-long .form-element__input {
  max-width: 70px;
}
.form-element:first-of-type {
  margin-left: 0;
}
.form-element__label {
  display: block;
  margin-right: 6px;
}
.form-element__label:only-child {
  margin-right: 0;
}
.form-element__label--last {
  margin-right: 0;
}
.form-element__label--validatable-item-select, .form-element__label--fullwidth {
  display: inline-block;
}
.form-element__input--select-type {
  margin-bottom: 8px;
}
.form-element__label--select, .form-element__label--validatable-item-select, .form-element__label--fullwidth {
  position: relative;
}
.form-element__label--select:after, .form-element__label--validatable-item-select:after, .form-element__label--fullwidth:after {
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #fff 93%, #fff 100%);
  display: block;
  height: 47px;
  position: absolute;
  right: 28px;
  top: 2px;
  width: 39px;
  z-index: 1060;
}
@media (max-width: 992px) {
  .form-element__label--validatable-item-select {
    margin: 8px 6px 0 0;
  }
}
@media (max-width: 768px) {
  .form-element__label--validatable-item-select {
    margin: 8px 6px 0 0;
  }
}
@media (max-width: 480px) {
  .form-element__label--validatable-item-select {
    margin: 8px 6px 8px 0;
  }
}
.form-element__label--fullwidth {
  width: 100%;
}
.form-element__label--checkbox {
  border-radius: 4px;
}
.form-element__label--radio {
  border: 1px solid #000;
  display: inline-block;
  line-height: normal;
  padding: 13px 15px;
}
@media (max-width: 992px) {
  .form-element__label--radio {
    margin-left: 0;
  }
}
@media (max-width: 480px) {
  .form-element__label--radio {
    margin-left: 0;
  }
}
.form-element__label--select, .form-element__label--validatable-item-select, .form-element__label--validatable-item-select-block {
  display: block;
  background: transparent;
}
.form-element__label--checkbox, .form-element__label--radio {
  background: #fff;
}
.form-element__label--disabled {
  border-color: #767676;
  color: #767676;
}
.form-element--readonly .form-element__sign--labelover {
  color: #767676;
}
.form-element--readonly .form-element__input {
  border-color: #767676;
  color: #767676;
}
.form-element--disabled .form-element__sign--labelover {
  color: #767676;
}
.form-element--disabled .form-element__input {
  border-color: #767676;
  color: #767676;
}
.form-element__checkbox, .form-element__radio {
  margin-right: 10px;
}
.form-element__input {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  border: 1px solid #000;
  border-radius: 0;
  line-height: normal;
  padding: 13px;
}
.form-element--danger .form-element__input {
  outline: 2px solid #ad112a;
}
.form-element--danger .form-element__input:focus {
  outline: 2px solid #ffb500;
}
.form-element__input--xxl {
  min-width: 100px;
}
@media (min-width: 992px) {
  .form-element__input--xxl {
    margin: 0 6px 0 0;
    width: calc(100% - 293px);
  }
}
@media (max-width: 992px) {
  .form-element__input--xxl {
    margin: 0 6px 0 0;
    width: calc(100% - 415px);
  }
}
@media (max-width: 768px) {
  .form-element__input--xxl {
    margin: 0 6px 6px 0;
    width: calc(100% - 40px);
  }
}
@media (max-width: 480px) {
  .form-element__input--xxl {
    margin: 0 6px 6px 0;
    width: calc(100% - 10px);
  }
}
.form-element__input--xl {
  min-width: 100px;
}
@media (min-width: 992px) {
  .form-element__input--xl {
    margin: 0 6px 0 0;
    width: calc(100% - 390px);
  }
}
@media (max-width: 992px) {
  .form-element__input--xl {
    margin: 0 6px 0 0;
    width: calc(100% - 390px);
  }
}
@media (max-width: 768px) {
  .form-element__input--xl {
    margin: 0 6px 6px 0;
    width: calc(100% - 484px);
  }
}
@media (max-width: 480px) {
  .form-element__input--xl {
    margin: 0 6px 6px 0;
    width: calc(100% - 10px);
  }
}
.form-element__input--unit {
  min-width: 100px;
}
@media (min-width: 992px) {
  .form-element__input--unit {
    margin: 0 6px 0 0;
    width: 155px;
  }
}
@media (max-width: 992px) {
  .form-element__input--unit {
    margin: 0 6px 0 0;
    width: 120px;
  }
}
@media (max-width: 768px) {
  .form-element__input--unit {
    margin: 0 6px 0 0;
    width: 120px;
  }
}
@media (max-width: 480px) {
  .form-element__input--unit {
    margin: 0 6px 6px 0;
    width: 120px;
  }
}
.form-element__input--l {
  min-width: 100px;
}
@media (min-width: 992px) {
  .form-element__input--l {
    margin: 0 6px 0 0;
    width: calc(100% - 418px);
  }
}
@media (max-width: 992px) {
  .form-element__input--l {
    margin: 0 6px 0 0;
    width: calc(100% - 498px);
  }
}
@media (max-width: 768px) {
  .form-element__input--l {
    margin: 0 6px 6px 0;
    width: calc(100% - 484px);
  }
}
@media (max-width: 480px) {
  .form-element__input--l {
    margin: 0 6px 6px 0;
    width: calc(100% - 10px);
  }
}
.form-element__input--m {
  min-width: 100px;
}
@media (min-width: 992px) {
  .form-element__input--m {
    margin: 0 6px 0 0;
    width: calc(100% - 510px);
  }
}
@media (max-width: 992px) {
  .form-element__input--m {
    margin: 0 6px 0 0;
    width: calc(100% - 506px);
  }
}
@media (max-width: 768px) {
  .form-element__input--m {
    margin: 0 6px 6px 0;
    width: calc(100% - 70px);
  }
}
@media (max-width: 480px) {
  .form-element__input--m {
    margin: 0 6px 6px 0;
    width: calc(100% - 10px);
  }
}
.form-element__sign {
  font-size: 1.11111em;
  font-weight: 600;
  padding: 0 10px;
}
@media (max-width: 480px) {
  .form-element__sign {
    margin: 0;
  }
}
.form-element__sign--label {
  display: block;
  font-size: 1em;
  font-weight: 300;
  padding: 6px 0 4px;
}
@media (max-width: 768px) {
  .form-element__sign--label {
    font-size: 0.8889em;
  }
}
.form-element__labelover-container {
  display: inline;
  position: relative;
}
.form-element__labelover-container .form-element__input {
  padding-left: 2em;
}
.form-element__sign--labelover {
  position: absolute;
  top: -3px;
}
.form-element__input--select {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #000;
  background: #fff url("../../images/icon-caret-down.svg") no-repeat right 11px top 50%;
  background-size: 20px 20px;
  border-radius: 0;
  line-height: normal;
  padding: 13px 40px 13px 11px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: auto;
}
.form-element__input--full-width {
  width: 100%;
}
.form-element__input--select::-ms-expand {
  display: none;
}
.form-element__input--textarea {
  resize: none;
  width: 100%;
}
.form-element__input--single-l {
  min-width: 100px;
}
@media (min-width: 992px) {
  .form-element__input--single-l {
    margin: 0 6px 0 0;
    width: calc(100% - 310px);
  }
}
@media (max-width: 992px) {
  .form-element__input--single-l {
    margin: 0 6px 0 0;
    width: calc(100% - 440px);
  }
}
@media (max-width: 768px) {
  .form-element__input--single-l {
    margin: 0 6px 0 0;
    width: calc(100% - 200px);
  }
}
@media (max-width: 480px) {
  .form-element__input--single-l {
    margin: 0 6px 0 0;
    width: calc(100% - 0px);
  }
}
.form-element__input--single-xl {
  min-width: 100px;
}
@media (min-width: 992px) {
  .form-element__input--single-xl {
    margin: 0 6px 0 0;
    width: calc(100% - 240px);
  }
}
@media (max-width: 992px) {
  .form-element__input--single-xl {
    margin: 0 6px 0 0;
    width: calc(100% - 340px);
  }
}
@media (max-width: 768px) {
  .form-element__input--single-xl {
    margin: 0 6px 0 0;
    width: calc(100% - 150px);
  }
}
@media (max-width: 480px) {
  .form-element__input--single-xl {
    margin: 0 6px 0 0;
    width: calc(100% - 0px);
  }
}
@media (max-width: 480px) {
  .form-element__label.form-element__label--radio {
    margin: 0 6px 6px 0;
  }
}
.form-element__label.form-element__label--radio-inline-block {
  display: inline-block;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.summary-panel {
  background: transparent;
  border: 1px solid #000;
  width: 100%;
}
@media (min-width: 992px) {
  .summary-panel:not(.summary-panel--cfp) {
    width: 295px;
  }
}
@media (min-width: 1200px) {
  .summary-panel:not(.summary-panel--cfp) {
    width: 360px;
  }
}
@media (max-width: 992px) {
  .summary-panel {
    margin: 1.5em 0 0;
  }
}
.summary-panel.is-sticky {
  top: 1.5em;
}
@media (min-width: 992px) {
  .summary-panel.is-sticky {
    width: 295px;
  }
}
@media (min-width: 1200px) {
  .summary-panel.is-sticky {
    width: 360px;
  }
}
@media (min-width: 992px) {
  .summary-panel.is-sticky {
    position: fixed;
    z-index: 9999;
  }
}
@media screen and (max-device-width: 480px) {
  .summary-panel.is-sticky {
    position: relative;
  }
}
.summary-panel.is-full-screen {
  bottom: 0;
  overflow-x: auto;
  top: 0;
}
.summary-panel__header {
  border-bottom: 1px solid #000;
  padding: 6px 20px 5px;
}
.summary-panel__header-title {
  font-size: 2em;
  font-weight: 700;
  line-height: 1.5;
  margin-top: 0;
}
.summary-panel__header-expand-link {
  background-position: 0 1.55em;
  display: none;
  float: right;
  padding-top: 6px 0 0;
}
.summary-panel__body {
  min-height: 300px;
  padding: 5px 20px 20px;
}
.summary-panel__body.is-expanded {
  height: 100%;
}
.summary-panel__footer {
  padding: 5px 20px 20px;
}
.summary-panel__btn {
  margin: 0 0 0 4px;
}
.summary-panel__calculations {
  padding: 5px 20px;
}
.summary-panel__summary {
  display: inline-block;
  font-size: 1em;
  font-weight: 600;
  line-height: 1;
  width: 140px;
}
.summary-panel__result {
  font-size: 1.83333em;
  font-weight: 600;
  line-height: 1.1;
}
.summary-panel__message {
  font-size: 1em;
  font-weight: 600;
  line-height: 1;
}
.summary-panel__cfp {
  background: none;
  border-color: #979797;
  margin-top: 10px;
  padding-bottom: 12px;
}
.summary-panel__cfp-title {
  font-size: 0.9em;
  font-weight: 600;
}
.summary-panel__cfp-info {
  font-size: 0.9em;
}
@media screen and (min-width: 400px) {
  .summary-panel__cfp-item {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
}
@media screen and (min-width: 400px) {
  .summary-panel__cfp-item p {
    width: 45%;
  }
}

.print-white .summary-panel {
  background: #fff;
}

.info-button {
  border-radius: 4px;
  background: #007db9;
  color: #fff;
  display: block;
  margin-bottom: 1.5em;
  margin-top: 1.5em;
  padding: 1.25em 1.25em 1.5em;
  position: relative;
  text-shadow: none;
}
.info-button:active, .info-button:focus, .info-button:hover {
  background-image: none;
  background: #005b86;
  color: #fff;
  border: 0;
}
.info-button:active:after, .info-button:focus:after, .info-button:hover:after {
  display: none;
}
.info-button__note-title {
  margin-top: 0;
}
.info-button__note-title, .info-button__attention-content {
  font-weight: 600;
}

.document-button {
  background: linear-gradient(-3deg, transparent 66%, #005b86 66.5%) no-repeat border-box, linear-gradient(0deg, #fff 60%, #007db9 54%) no-repeat border-box;
  color: #000;
  padding-top: 2%;
  border: 2px solid #ebebeb;
  border-radius: 12px;
}
.document-button h2 {
  margin: 0.5em 0 2em;
  padding-bottom: 1em;
  font-size: 1.2em;
  color: #fff;
  font-weight: 400;
}
.document-button p {
  font-size: 1em;
}
.document-button .info-button__icon-container {
  fill: #000;
}
.document-button:active, .document-button:hover, .document-button:focus {
  border: 2px solid #000;
  border-color: #ebebeb;
  background: linear-gradient(-3deg, transparent 66%, #007db9 66.5%) no-repeat border-box, linear-gradient(0deg, #fff 60%, #005b86 54%) no-repeat border-box;
  color: #007db9;
}
.document-button:active .info-button__icon-container, .document-button:hover .info-button__icon-container, .document-button:focus .info-button__icon-container {
  fill: #007db9;
}

@media (min-width: 768px) {
  .hub-buttons {
    display: flex;
  }
}
@media (min-width: 768px) {
  .hub-buttons__wrapper {
    display: flex;
  }
}
.hub-buttons .info-button {
  width: 100%;
}

.info-content {
  display: block;
  margin: 3em 0 0;
}
.info-content--first {
  margin: 0;
}
.info-content__sub-title {
  font-size: 2em;
}
.info-content__sub-title--emphasis {
  font-weight: 600;
}
.info-content__result {
  display: block;
  font-size: 4.5em;
  font-weight: 600;
}
.info-content__sub-info {
  padding-top: 1em;
}
.info-content__intro-text {
  font-size: 18px;
  font-weight: 600;
}
@media (min-width: 992px) {
  .info-content__intro-text {
    font-size: 18px;
  }
}
.cfp-index-section .info-content__intro-text {
  margin-top: 2rem;
}
.info-content__title-second {
  font-weight: normal;
}
.info-content__button {
  background-color: transparent;
  background-image: linear-gradient(#4175ac, #4175ac);
  background-position: 0 100%;
  background-repeat: repeat-x;
  background-size: 1px 1px;
  border: 0;
  color: #4175ac;
  cursor: pointer;
  font-size: 1rem;
  text-decoration: none;
  text-shadow: 0.04545em 0 0 #fff, 0.09091em 0 0 #fff, 0.13636em 0 0 #fff, -0.04545em 0 0 #fff, -0.09091em 0 0 #fff, -0.13636em 0 0 #fff, 0 0.04545em 0 #fff, 0 0.09091em 0 #fff, 0 -0.04545em 0 #fff, 0 -0.09091em 0 #fff;
}
.info-content__button:hover {
  background-image: none;
  text-decoration: none;
}
.info-content__link {
  text-decoration: underline;
}
.info-content__link:active, .info-content__link:focus, .info-content__link:hover {
  text-decoration: none;
}
.info-content__cfp-actions .info-content__link {
  padding: 0.5em 0;
}
.info-content__cfp-actions {
  display: flex;
  flex-direction: column;
}
.info-content__note {
  border: 1px solid;
  font-size: 1.125em;
  margin-top: 1.5em;
  margin-bottom: 24px;
  padding: 15px 15px 15px 50px;
  position: relative;
}
.info-content__note p {
  font-size: 1em;
  margin: 0 0 8px;
}
.info-content__note p:last-child {
  margin: 0;
}
@media (max-width: 991px) {
  .info-content__note {
    font-size: 1em;
  }
}
.info-content__note-inner {
  background: #000;
  left: 0;
  padding: 10px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1;
}
.info-content__note-title {
  font-size: 1.55556em;
  font-weight: 600;
}
.info-content__note-title:first-of-type {
  margin-top: 0;
}
.info-content__note--danger {
  background: #ad112a;
  color: #fff;
}
.info-content__note--danger .info-content__link {
  color: #fff;
  text-decoration: underline;
}
.info-content__note--danger .info-content__link:active, .info-content__note--danger .info-content__link:hover {
  text-decoration: none;
}
.info-content__note--danger .icon__container {
  fill: #fff;
}
.info-content--no-margin {
  margin: 0;
}
.info-content__note--nomargin {
  margin: 0;
}
.info-content__note--top-message {
  background: #000;
  border: 0;
  color: #fff;
  min-height: 44px;
}
.info-content__note--top-message .info-content__link {
  color: #fff;
  text-decoration: underline;
}
.info-content__note--top-message .info-content__link:active, .info-content__note--top-message .info-content__link:hover {
  text-decoration: none;
}
.info-content__icon {
  position: absolute;
  left: 12px;
  top: 16px;
}
.info-content__attention-content {
  font-weight: 600;
}

.hub-page .info-content__title {
  margin-top: 37px;
}

.calc-table {
  width: 100%;
}
.calc-table__divider, .calc-table__summary {
  text-align: left;
}
.calc-table__summary {
  padding-top: 6px;
}
@media (min-width: 992px) {
  .calc-table__summary {
    padding-top: 0;
  }
}
.calc-table__result {
  text-align: right;
}
.calc-table__divider, .calc-table__summary, .calc-table__result {
  padding-top: 12px;
}
.calc-table__divider {
  border-bottom: 1px solid #000;
  padding-bottom: 24px;
}
.calc-table__link {
  color: #125397;
  font-size: 1.125em;
}

.is-active .calc-table__link {
  font-weight: 700;
}
.is-active .calc-table__result {
  font-weight: 700;
  color: #125397;
}

svg:not(:root) {
  overflow: hidden;
}

.icon {
  display: inline-block;
  line-height: initial;
  transition: transform 0.3s ease;
  fill: #007db9;
}
.icon__container--rotate-180, .icon--rotate-180 {
  transform: rotate(180deg);
}
.icon--link {
  background-image: none;
  position: relative;
  text-decoration: none;
  z-index: 999;
}
.icon--link:after {
  content: "";
  position: absolute;
  z-index: -1;
  display: block;
  background: #f8f8f8;
  height: 3px;
  bottom: 0;
  left: 0;
  width: 21px;
}
@media screen and (min-width: 0\0 ) {
  .icon--link:after {
    bottom: 3px;
  }
}
.icon__source, .icon__container {
  width: 20px;
  height: 20px;
  vertical-align: sub;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .icon__source, .icon__container {
    vertical-align: text-bottom;
  }
}
.icon--big .icon__source, .icon--big .icon__container {
  width: 26px;
  height: 26px;
  vertical-align: bottom;
}
.icon__assistive-text {
  position: absolute;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
}
.icon--left {
  float: left;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .icon--summary-table {
    vertical-align: 4%;
  }
}

_::-webkit-:not(:root:root),
.icon--link:after {
  bottom: 1px;
}

.more-info__link:focus .icon--link:after {
  display: none;
}

.modal {
  background: #fff;
  border: 3px solid #333;
  left: 25%;
  padding: 20px;
  position: fixed;
  top: -400px;
  z-index: 9999999;
}
.modal.in {
  top: 4%;
}
.modal-cover {
  background: rgba(255, 255, 255, 0.8);
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  z-index: 9999;
}

.more-info {
  display: block;
}
.more-info--padding {
  padding: 1.2em 0;
}
.more-info__link-icon .icon__container {
  fill: #007db9;
}
.more-info__link--span {
  cursor: pointer;
  color: #fff;
  background-color: none;
}
.more-info__link--span:hover, .more-info__link--span:active {
  color: #ccc;
}
.more-info__item {
  display: inline-block;
}
.more-info__item .more-info__link {
  margin: 0 0.75em 0 0;
}
.more-info__item:last-of-type {
  margin-right: 0;
}
.more-info__tab-pane {
  border-left: 3px solid #000;
  display: none;
  margin: 0.75em 0 0;
  padding: 0 0 0 0.75em;
}
.more-info__tab-pane--scope p {
  margin: 0 0 0.75rem;
}
.more-info__tab-pane.is-active {
  display: block;
}
.more-info__tab-pane:focus {
  outline: 0;
}
.more-info__list {
  padding: 0 0 1em 1em;
}
.more-info__list--ordered {
  list-style-type: decimal;
}
.more-info__subheading {
  font-size: 1em;
  margin-top: 16px;
}
.more-info__paragraph {
  margin-top: 4px;
}

.calc-table .more-info__link {
  font-size: 1em;
}
.calc-table .more-info__tab-pane p {
  font-size: 1em;
}

.collapse-menu {
  margin-top: 2.25em;
}
.collapse-menu__container:after, .collapse-menu__title:after {
  background: #c8c8c8;
  content: "";
  display: block;
  height: 1px;
  margin-bottom: 1rem;
  margin-top: 1rem;
  width: 100%;
}
.collapse-menu__section-content {
  margin-bottom: 1.5em;
  overflow: auto;
}
.collapse-menu__section-title {
  margin-bottom: 0.625em;
}
.collapse-menu__button {
  background: none;
  border: 0;
  cursor: pointer;
  text-align: left;
}
@media (min-width: 991px) {
  .collapse-menu__button {
    font-size: 1.125em;
  }
}
.collapse-menu__button:active, .collapse-menu__button:focus, .collapse-menu__button:hover {
  text-decoration: underline;
}

.upload-box {
  background-color: #fff;
  border: 1px solid #000;
  font-size: 1.25rem;
  margin-bottom: 14px;
  padding: 50px 20px;
  position: relative;
}
.upload-box--has-advanced-upload {
  outline: 0;
  outline-offset: -10px;
  transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
}
.upload-box--is-dragover {
  background-color: #f3f6fa;
}
.upload-box__dragndrop, .upload-box__icon {
  display: none;
}
.upload-box__dragndrop {
  display: inline;
}
.upload-box__icon {
  display: block;
  fill: #92b0b3;
  height: 80px;
  margin-bottom: 40px;
  width: 100%;
}
.upload-box__input {
  text-align: center;
}
.upload-box--is-error .upload-box__input, .upload-box--is-success .upload-box__input, .upload-box--is-uploading .upload-box__input {
  visibility: hidden;
}
.upload-box__error, .upload-box__success, .upload-box__uploading {
  display: none;
}
.upload-box__error {
  color: #ad112a;
}
.upload-box--is-error .upload-box__error, .upload-box--is-success .upload-box__success, .upload-box--is-uploading .upload-box__uploading {
  display: block;
  left: 0;
  padding: 0 20px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
}
.upload-box__uploading {
  font-style: italic;
}
.upload-box__success {
  animation: appear-from-inside 0.25s ease-in-out;
}
.upload-box__button {
  display: none;
}
.upload-box__button:focus, .upload-box__button:hover {
  background-color: #0f3c4b;
}
.upload-box__restart {
  font-weight: 700;
}
.upload-box__ipload-icon {
  display: block;
  margin: 0 auto;
  width: 100px;
}
.upload-box__ipload-icon .icon__container {
  height: 100px;
  width: 100px;
}
.upload-box__file-label {
  font-weight: 300;
}
.upload-box__file-label-action {
  color: #125397;
  text-decoration: underline;
  text-underline-offset: 3px;
  cursor: pointer;
  font-weight: 400;
}
.upload-box__file-label-action:hover, .upload-box__file-label-action:active {
  color: #125397;
  text-decoration: underline;
}

@keyframes appear-from-inside {
  from {
    transform: translateY(-50%) scale(0);
  }
  75% {
    transform: translateY(-50%) scale(1.1);
  }
  to {
    transform: translateY(-50%) scale(1);
  }
}
.js .upload-box__file {
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 0.1px;
  z-index: -1;
}
.js .upload-box__file + label {
  cursor: pointer;
  display: inline-block;
  max-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.js .upload-box__file.has-focus + label, .js .upload-box__file:focus + label {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}

.no-js .upload-box__file + label {
  display: none;
}

.no-js .upload-box__button {
  display: block;
}

.validation-message {
  color: #ad112a;
  display: block;
  font-size: 1.125em;
  font-weight: 600;
  margin-top: 12px;
}
.validation-message--error {
  position: relative;
}
.validation-message--error:before {
  border-left: 2px solid #ad112a;
  bottom: 0;
  content: "";
  left: -20px;
  position: absolute;
  top: 0;
}
@media (max-width: 768px) {
  .validation-message--error:before {
    left: -10px;
  }
}
.validation-message--error .validatable-item__link {
  color: #ad112a;
}

.field-validation-error {
  color: #c70a0a;
}

.cfp-card {
  background: #ebebeb;
  color: #000;
  padding: 1.2rem 2rem;
  margin-top: 1.2em;
  border-radius: 4px;
}
.cfp-card:hover {
  background-color: #d2d2d2;
}
.cfp-card__heading {
  font-weight: bold;
  font-size: 1.125em;
  margin-bottom: 0.5rem;
}
.cfp-card__detail {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.cfp-card__detail__item {
  flex: 1 1 auto;
}
.cfp-card__detail--authority {
  font-weight: 600;
}
.cfp-card__detail--status {
  font-weight: 600;
}
.cfp-card__detail--action {
  color: #007db9;
  text-align: right;
}
.cfp-card-link {
  display: block;
}

.unread-counter, ul.doc-card-list li .title .unread {
  color: #a4002d;
  font-weight: 600;
}

ul.doc-card-list li {
  background: #fff;
  color: #000;
  margin: 1em 0;
  padding: 1.2rem 2rem;
  border: #848484 solid 1px;
  border-radius: 4px;
}
ul.doc-card-list li .title {
  margin-bottom: 8px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
ul.doc-card-list li .title .title-content {
  flex: 10 1 auto;
}
ul.doc-card-list li .title .doc-title {
  font-size: 20px;
  font-weight: 600;
}
ul.doc-card-list li .title .doc-title .name-title {
  font-weight: normal;
}
ul.doc-card-list li .title .unread {
  flex: 1 1 auto;
  text-align: right;
}
ul.doc-card-list li .doc-at,
ul.doc-card-list li .doc-date,
ul.doc-card-list li .doc-on-behalf-of {
  font-weight: 600;
}
ul.doc-card-list li .actions {
  margin-top: 1.2rem;
}
ul.doc-card-list li .actions a {
  margin-right: 1.2rem;
}

.card {
  background: #ebebeb;
  padding: 0.625rem;
  font-weight: 400;
  font-size: 1.125em;
  margin-top: 0.75rem;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
@media (max-width: 992px) {
  .card {
    flex-wrap: wrap;
  }
}
@media (min-width: 992px) {
  .card.cfp-index-list {
    max-width: 600px;
  }
}
.card--warning {
  background: #fff4da;
}
.card__item {
  flex: 1 1 auto;
  line-height: 1.6;
  word-wrap: break-word;
}
.card__item--icon {
  width: 20px;
  flex: 0;
  margin-right: 10px;
}
@media screen\0  {
  .card__item--icon {
    margin-right: 27px;
  }
}
@media (max-width: 992px) {
  .card__item--icon {
    display: none;
  }
}
.card__item--data {
  flex: 1;
}
.card__item--status {
  text-align: right;
}
.card__item--controls {
  text-align: right;
}
.card__link {
  display: block;
  text-shadow: none;
}
.card__link .icon__container {
  width: 14px;
  height: 14px;
}
.card__item-status {
  text-transform: uppercase;
}
.card__item-status--warning {
  color: #ad112a;
}
.card__item-split {
  margin-right: 1.5rem;
  display: inline-block;
}
.card__item-split:last-of-type {
  margin-right: 0;
}
.card__item-split--smallcaps {
  text-transform: uppercase;
  font-size: 0.875rem;
}
.card--link {
  background: transparent;
  border: 1px #125397 solid;
  color: #125397;
  cursor: pointer;
}
.card--link:hover, .card--link:active, .card--link:focus {
  outline: 1px #125397 solid;
}
@media (max-width: 992px) {
  .card__item--icon, .card__item--status, .card__item--info, .card__item--controls {
    flex: 1 1 100%;
    text-align: left;
  }
}

.summary-table {
  margin-bottom: 15px;
  overflow-y: hidden;
}
@media (max-width: 750px) {
  .summary-table {
    padding-bottom: 15px;
  }
}
.summary-table--margin-top {
  margin-top: 24px;
}
.summary-table__data-table {
  border: 1px solid #ddd;
  width: 100%;
}
.summary-table__header-cell {
  text-align: left;
}
.summary-table__row {
  background-color: #f9fafb;
}
.summary-table__row--summary {
  display: none;
}
.summary-table__row--dark {
  background-color: #ebeced;
}
.summary-table__row--collapse {
  display: none;
}
.summary-table__row--collapse-in {
  display: block;
}
.summary-table__header-cell, .summary-table__cell {
  border: 1px solid #fff;
  padding: 2px 10px;
}
.summary-table__row--darker, .summary-table__header {
  background-color: #6d7879;
  color: #fff;
}
.summary-table__col-1 {
  min-width: 100px;
}
.summary-table__col-2 {
  min-width: 200px;
}
.summary-table__col-3 {
  min-width: 200px;
}
.summary-table__cell--summary {
  padding: 15px;
}
.summary-table__info-link {
  background-image: linear-gradient(#fff, #fff);
  color: #fff;
  text-shadow: 0.04545em 0 0 #6d7879, 0.09091em 0 0 #6d7879, 0.13636em 0 0 #6d7879, -0.04545em 0 0 #6d7879, -0.09091em 0 0 #6d7879, -0.13636em 0 0 #6d7879, 0 0.04545em 0 #6d7879, 0 0.09091em 0 #6d7879, 0 -0.04545em 0 #6d7879, 0 -0.09091em 0 #6d7879;
}
.summary-table__info-link:focus, .summary-table__info-link:active, .summary-table__info-link:hover {
  color: #fff;
}
.summary-table__link-icon .icon__container {
  fill: #fff;
}
.summary-table__link-icon:after {
  background: #6d7879;
}
.summary-table--light .summary-table__data-table {
  border: transparent;
}
.summary-table--light .summary-table__row--darker,
.summary-table--light .summary-table__header {
  background-color: transparent;
  color: #000;
}
.summary-table--light .summary-table__header-cell,
.summary-table--light .summary-table__cell {
  border: 0;
  padding: 2px 0;
}
.summary-table--light .summary-table__row {
  background-color: transparent;
  border-bottom: 1px solid #ddd;
}

table {
  border-spacing: 0;
}

.table {
  margin-bottom: 15px;
  overflow-y: hidden;
}
@media (max-width: 750px) {
  .table {
    padding-bottom: 15px;
  }
}
.table--margin-top {
  margin-top: 24px;
}
.table__data-table {
  border: transparent;
  width: 100%;
}
.table__header-cell, .table__footer-cell {
  text-align: left;
}
.table__footer-cell {
  vertical-align: text-top;
}
.table__header-cell, .table__cell {
  border-bottom: 1px solid #d4d4d4;
}
.table__footer-row--divider-bottom .table__footer-cell, .table__row--divider-bottom .table__cell {
  border-bottom: 1px solid #7e7e7e;
}
.table__footer-row--divider-top .table__footer-cell, .table__row--divider-top .table__cell {
  border-top: 1px solid #7e7e7e;
}
.table__header-cell, .table__footer-cell, .table__cell {
  padding: 10px 5px 10px 0;
}
.table__cell--span:nth-child(2) {
  padding-top: 5px;
}
.table__summary-text {
  display: block;
  font-style: italic;
  font-weight: 300;
}
.table__row--bold:last-child td {
  border-bottom: 2px solid #7e7e7e;
}
.table__row--bold:last-child th {
  border-bottom: 2px solid #7e7e7e;
}
.table__border-bottom-add th,
.table__border-bottom-add td {
  border-bottom: 2px solid #7e7e7e;
}
.table__border-bottom:last-child th {
  border-bottom-color: #7e7e7e;
}
.table__border-bottom:last-child td {
  border-bottom-color: #7e7e7e;
}
.table__border-collapse {
  border-collapse: collapse;
}
.table__border-bottom-hide th {
  border-bottom: 0;
}
.table__border-bottom-hide td {
  border-bottom: 0;
}
.table__cell-display {
  display: table-cell;
}
.table__highlighted {
  background: #ebebeb;
}
.table__highlighted td {
  border-bottom: 0;
}
.table__highlighted:not(.table__highlighted-print) td:first-child {
  padding-left: 15px;
}
.table__highlighted:not(.table__highlighted-print) td:last-child {
  padding-right: 15px;
}
.table__margin-none {
  margin-bottom: 0;
}
.table__row-hide-last-border:last-child td {
  border-bottom: 0;
}
.table .table__fixed td {
  padding-right: 10px;
}
.table .table__fixed th {
  padding-right: 10px;
}
.table .table__row--highlighted {
  background: #ebebeb;
}

.u-table-border-width td {
  border-width: 2px;
}
.u-table-border-width th {
  border-width: 2px;
}
.u-table-border-width tr:last-child td {
  border-width: 2px;
}

.u-table-border-top th {
  border-top: 1px solid #000;
}
.u-table-border-top td {
  border-top: 1px solid #000;
}

div.table.table-indented:not(.table__highlighted) table tr:not(.table__row--highlighted) td:first-child:before,
div.table.table-indented:not(.table__highlighted) table tr:not(.table__row--highlighted) th:first-child:before {
  background: #f8f8f8;
  bottom: -3px;
  content: "";
  height: 5px;
  left: 0;
  position: absolute;
  width: 10px;
}

div.table.table-indented:not(.table__highlighted) table tr:not(.table__row--highlighted) td {
  position: relative;
}

div.table.table-indented:not(.table__highlighted) table tr:not(.table__row--highlighted) td:last-child:after,
div.table.table-indented:not(.table__highlighted) table tr:not(.table__row--highlighted) th:last-child:after {
  bottom: -3px;
  background: #f8f8f8;
  content: "";
  height: 5px;
  position: absolute;
  right: 0;
  width: 6px;
}

.download__pbs div.table.table-indented:not(.table__highlighted) table tr:not(.table__row--highlighted) td:first-child:before,
.download__pbs div.table.table-indented:not(.table__highlighted) table tr:not(.table__row--highlighted) th:first-child:before {
  background: #fff;
  bottom: -3px;
  content: "";
  height: 5px;
  left: -2px;
  position: absolute;
  width: 10px;
}
.download__pbs div.table.table-indented:not(.table__highlighted) table tr:not(.table__row--highlighted) td {
  position: relative;
}
.download__pbs div.table.table-indented:not(.table__highlighted) table tr:not(.table__row--highlighted) td:last-child:after,
.download__pbs div.table.table-indented:not(.table__highlighted) table tr:not(.table__row--highlighted) th:last-child:after {
  background: #fff;
  bottom: -3px;
  content: "";
  height: 5px;
  position: absolute;
  right: 0;
  width: 6px;
}

.table__padding tr td:first-child,
.table__padding tr th:first-child {
  padding-left: 15px;
}

.table__padding tr td:last-child,
.table__padding tr th:last-child {
  padding-right: 15px;
}

.table__row-padding-bottom td,
.table__row-padding-bottom th {
  padding-bottom: 2em;
}

@media screen and (max-width: 500px) {
  .table__row-flex {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
  .table__row-flex td {
    width: 100%;
  }
  .table__row-flex td.table__cell--borderless {
    border-bottom: 0;
  }
  .table:not(.table__highlighted) .table__row-flex:last-child td:first-child {
    border-top: 2px solid #d4d4d4;
  }
  .table__highlighted td {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media all and (max-width: 768px) {
  .table__cell--10,
.table__cell--15,
.table__cell--20,
.table__cell--25,
.table__cell--30,
.table__cell--35,
.table__cell--40,
.table__cell--45,
.table__cell--60,
.table__cell--70 {
    padding-right: 1em;
    width: 120px;
  }
}
@media all and (min-width: 500px) {
  .table__cell-25 {
    width: 30%;
  }
  .table:not(.table__highlighted) .table__row-flex:last-child td {
    border-top: 2px solid #d4d4d4;
  }
}
@media all and (min-width: 768px) {
  .table__cell--10 {
    width: 10%;
  }
  .table__cell--15 {
    width: 15%;
  }
  .table__cell--20 {
    width: 20%;
  }
  .table__cell--25 {
    width: 25%;
  }
  .table__highlighted .table__cell--25 {
    width: 25.5%;
  }
  .table__highlighted .table__cell--30 {
    width: 30.5%;
  }
  .table__cell--30 {
    width: 30%;
  }
  .table__cell--35 {
    width: 35%;
  }
  .table__cell--40 {
    width: 40%;
  }
  .table__cell--45 {
    width: 45%;
  }
  .table__cell--60 {
    width: 60%;
  }
  .table__cell--70 {
    width: 70%;
  }
  .table__border-bottom:last-child th {
    border-bottom: 0;
  }
  .table__border-bottom:last-child td {
    border-bottom: 0;
  }
  .layout__table_flex {
    display: flex;
    justify-content: space-between;
  }
  .layout__table_flex .table__flex {
    display: flex;
    flex-wrap: wrap;
    width: 48%;
  }
  .layout__table_flex .table__flex h3 {
    padding-bottom: 15px;
    width: 100%;
  }
  .layout__table_flex .table__flex table {
    display: flex;
    flex-direction: column;
    height: calc(100% - 5em);
  }
  .layout__table_flex .table__flex table tbody {
    height: 100%;
  }
  .layout__table_flex .table__flex table tr {
    width: 100%;
  }
  .layout__table_flex .table__flex table tr td {
    width: 100%;
  }
  .layout__table_flex .table__flex table tr th {
    width: 100%;
  }
  .table__border-bottom-hide--md th {
    border-bottom: 0;
  }
  .table__border-bottom-hide--md td {
    border-bottom: 0;
  }
}
@media all and (min-width: 850px) {
  .table__fixed {
    table-layout: fixed;
  }
}
@media all {
  .print-white {
    background: #fff;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
  .print-break {
    background: #fff;
    page-break-before: always;
  }
}
.avoid_pagebreak {
  page-break-inside: avoid;
}

.grid-table {
  border: solid 1px #000;
  border-collapse: collapse;
  border-spacing: 0;
  font: normal 13px Arial, sans-serif;
}

.grid-table thead th {
  background-color: #000;
  border: solid 1px #000;
  color: #fff;
  padding: 10px;
  text-align: left;
}

.grid-table tbody td {
  border: solid 1px #000;
  color: #333;
  padding: 10px;
}

.nowrap {
  white-space: nowrap;
}

.page-nav {
  display: block;
  overflow: visible;
  height: 27px;
}
.page-nav__link {
  background-position: 0 90%;
}
.page-nav__link:hover, .page-nav__link:focus {
  background-position: 0 90%;
}
.page-nav__link:focus .page-nav__icon-container, .page-nav__link:active .page-nav__icon-container, .page-nav__link:hover .page-nav__icon-container {
  fill: #4175ac;
}
.page-nav__link--left {
  float: left;
}
.page-nav__link--right {
  float: right;
}
.page-nav__icon:after {
  bottom: 0%;
}
.page-nav__icon-container {
  fill: #125397;
}
.page-nav__link--disabled {
  background-image: linear-gradient(#c8c8c8, #c8c8c8);
  color: #c8c8c8;
  cursor: no-drop;
}
.page-nav__link--disabled .page-nav__icon-container {
  fill: #c8c8c8;
}
.page-nav__link--disabled:hover, .page-nav__link--disabled:active, .page-nav__link--disabled:focus {
  color: #c8c8c8;
}
.page-nav__link--disabled:hover .page-nav__icon-container, .page-nav__link--disabled:active .page-nav__icon-container, .page-nav__link--disabled:focus .page-nav__icon-container {
  fill: #c8c8c8;
}

.validatable-item {
  border: 0;
  margin-top: 1.5em;
}
.validatable-item--additional {
  margin-top: 0.75em;
}
.validatable-item.is-added {
  background-color: transparent;
}
.validatable-item--danger {
  position: relative;
}
.validatable-item--danger:before {
  border-left: 2px solid #ad112a;
  bottom: 0;
  content: "";
  left: -1.25em;
  position: absolute;
  top: 0;
}
@media (max-width: 768px) {
  .validatable-item--danger:before {
    left: -10px;
  }
}
.validatable-item__title {
  font-size: 1.25em;
  font-weight: 400;
  line-height: 1.2em;
  width: 100%;
}
.validatable-item__title--group {
  font-weight: 600;
}
.validatable-item__overview {
  display: block;
  margin-top: 0.5em;
}
.validatable-item__footer {
  display: block;
  margin-top: 0.65em;
}
.validatable-item__example-text {
  display: block;
  font-size: 1.125em;
  font-weight: 300;
  padding-top: 10px;
}
.validatable-item__additional-information {
  border-left: 3px solid #000;
  margin: 0.75em 0 0;
  padding: 0 0 0 0.75em;
}

.validatable-item-group {
  border-left: 1px solid #c8c8c8;
  margin-bottom: 24px;
  margin-left: -24px;
  padding-left: 24px;
}
@media (max-width: 767px) {
  .validatable-item-group {
    margin-left: 0;
  }
}

.client-panel {
  background: transparent;
  border: 1px solid #000;
  margin-top: 12px;
  width: 100%;
}
@media (max-width: 992px) {
  .client-panel {
    margin: 1.5em 0 0;
  }
}
.client-panel.is-sticky {
  top: 1.5em;
}
@media (min-width: 992px) {
  .client-panel.is-sticky {
    width: 295px;
  }
}
@media (min-width: 1200px) {
  .client-panel.is-sticky {
    width: 360px;
  }
}
@media (min-width: 992px) {
  .client-panel.is-sticky {
    position: fixed;
    z-index: 9999;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .client-panel.is-sticky {
    top: 50px;
  }
}
@media screen and (max-device-width: 480px) {
  .client-panel.is-sticky {
    position: relative;
  }
}
.client-panel__header {
  border-bottom: 1px solid #000;
  padding: 6px 20px 5px;
}
.client-panel__header-title {
  font-size: 2em;
  font-weight: 700;
  line-height: 1.5;
  margin-top: 0;
}
.client-panel__header-expand-link {
  background-position: 0 1.55em;
  display: none;
  float: right;
  padding-top: 6px 0 0;
}
.client-panel__body {
  padding: 5px 20px 20px;
}
.client-panel__body.is-expanded {
  height: 100%;
}
.client-panel__footer {
  padding: 5px 20px 20px;
}
.client-panel__btn {
  margin: 0 0 0 4px;
}
.client-panel__calculations {
  padding: 5px 20px;
}
.client-panel__summary {
  display: inline-block;
  font-size: 1em;
  font-weight: 600;
  line-height: 1;
  width: 140px;
}
.client-panel__result {
  font-size: 1.83333em;
  font-weight: 600;
  line-height: 1.1;
}
.client-panel__message {
  font-size: 1em;
  font-weight: 600;
  line-height: 1;
}
.client-panel__cfp {
  background: none;
  border-color: #979797;
  margin-top: 10px;
  padding-bottom: 12px;
}
.client-panel__cfp-title {
  font-size: 0.9em;
  font-weight: 600;
}
.client-panel__cfp-info {
  font-size: 0.9em;
}
.client-panel.is-full-screen {
  bottom: 0;
  overflow-x: auto;
  top: 0;
}
@media (min-width: 992px) {
  .client-panel:not(.client-panel--cfp) {
    position: fixed;
    width: 228px;
  }
}
@media (min-width: 1200px) {
  .client-panel:not(.client-panel--cfp) {
    width: 277px;
  }
}
@media screen and (min-width: 400px) {
  .client-panel__cfp-item {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
  .client-panel__cfp-item p {
    width: 45%;
  }
}

.print-white .client-panel {
  background: #fff;
}

.cfp-nav-menu a {
  display: block;
  margin: 0.75rem 0;
}
.cfp-nav-menu__section {
  margin-bottom: 2.5rem;
}

.app-step-nav {
  margin-bottom: 30px;
}
.app-step-nav--disabled {
  pointer-events: none;
}

.app-step-nav__controls {
  padding: 3px 3px 0 0;
  text-align: right;
}

.js-step-controls-button {
  margin-top: 10px;
}

.app-step-nav__button {
  color: #1d70b8;
  cursor: pointer;
  background: none;
  border: 0;
  margin: 0;
}
.app-step-nav__button:focus, .app-step-nav__button:focus-visible {
  outline: 3px solid #ffb500;
}

.app-step-nav__button--title {
  font-size: 19px;
  font-weight: bold;
  line-height: 1.4;
  display: inline-block;
  padding: 0;
  text-align: left;
  color: #0b0c0c;
}

.app-step-nav__button--controls {
  font-size: 14px;
  font-weight: normal;
  line-height: 1;
  text-decoration: underline;
  text-decoration-thickness: max(1px, 0.0625rem);
  text-underline-offset: 0.1em;
  position: relative;
  padding: 0.5em 0;
}

.app-step-nav__button--controls:hover {
  text-decoration-thickness: max(3px, 0.1875rem, 0.12em);
  text-decoration-skip: none;
}

.app-step-nav__steps {
  padding: 0;
  margin: 0;
}

.app-step-nav__step {
  position: relative;
  padding-left: 45px;
  list-style: none;
}

.app-step-nav__step:after {
  content: "";
  position: absolute;
  z-index: 2;
  width: 0;
  height: 100%;
  border-left: solid 2px #b1b4b6;
  background: #fff;
  left: 0;
  margin-left: 12px;
  top: 15px;
}

.app-step-nav__step--active .app-step-nav__circle--number,
.app-step-nav__step--active:after {
  border-color: #0b0c0c;
}

.app-step-nav__circle {
  box-sizing: border-box;
  position: absolute;
  z-index: 5;
  top: 15px;
  left: 0;
  width: 26px;
  height: 26px;
  color: #0b0c0c;
  background: #fff;
  border-radius: 100px;
  text-align: center;
}

.app-step-nav__circle--number {
  font-size: 16px;
  font-weight: bold;
  line-height: 23px;
  border: solid 2px #b1b4b6;
}

.app-step-nav__circle--logic {
  font-size: 16px;
  font-weight: bold;
  line-height: 28px;
}

.app-step-nav__circle-inner {
  float: right;
  min-width: 100%;
}

.app-step-nav__circle-background {
  text-shadow: 0 -0.1em 0 #fff, 0.1em 0 0 #fff, 0 0.1em 0 #fff, -0.1em 0 0 #fff;
}

.app-step-nav__circle-step-label,
.app-step-nav__circle-step-colon {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: 0;
  padding: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  border: 0;
  white-space: nowrap;
}

.app-step-nav_main-header {
  text-align: left;
}

.app-step-nav__header {
  padding: 15px 0;
  border-top: solid 2px #b1b4b6;
}

.app-step-nav--active .app-step-nav__header {
  cursor: pointer;
}

.app-step-nav__header:hover .app-step-nav__circle {
  color: #1d70b8;
}

.app-step-nav__button:hover .app-step-nav__button--header {
  text-decoration: underline;
  text-decoration-thickness: max(1px, 0.0625rem);
  text-underline-offset: 0.1em;
  text-decoration-skip: none;
}

.app-step-nav__button:hover .app-step-nav__button--header:hover {
  text-decoration: underline;
  text-decoration-thickness: max(1px, 0.0625rem);
  text-underline-offset: 0.1em;
  text-decoration-skip: none;
}

.app-step-nav__button:hover .app-step-nav__toggle-link {
  text-decoration: underline;
  text-decoration-thickness: max(3px, 0.1875rem, 0.12em);
  text-underline-offset: 0.1em;
  text-decoration-skip: none;
}

.app-step-nav__button:hover .app-step-nav__toggle-link:hover {
  text-decoration-thickness: max(3px, 0.1875rem, 0.12em);
  text-underline-offset: 0.1em;
  text-decoration-skip: none;
}

.app-step-nav__title {
  color: #0b0c0c;
  font-size: 19px;
  font-weight: bold;
  line-height: 1.4;
  margin: 0;
}

.app-step-nav__toggle-link {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.2;
  display: block;
  color: #1d70b8;
  text-transform: capitalize;
}

.app-step-nav__panel {
  color: #0b0c0c;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.3;
}
.app-step-nav__panel--collapsed {
  display: none;
}

.app-step-nav__list {
  padding: 0;
  padding-bottom: 10px;
  list-style: none;
}

.app-step-nav__list-item {
  font-size: 1.125em;
}

.app-step-nav__link {
  text-decoration: underline;
  text-decoration-thickness: max(1px, 0.0625rem);
  text-underline-offset: 0.1em;
}

.app-step-nav__link:hover {
  text-decoration-thickness: max(3px, 0.1875rem, 0.12em);
  text-decoration-skip: none;
}

.app-step-nav__link-active-context {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: 0;
  padding: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  border: 0;
  white-space: nowrap;
}

.app-step-nav__list-item--active {
  position: relative;
  font-weight: normal;
}

.app-step-nav__list-item--active:before {
  box-sizing: border-box;
  border-top: 3px solid black;
  content: "";
  position: absolute;
  z-index: 5;
  top: 0.6em;
  left: -45px;
  margin-top: -1px;
  margin-left: 13px;
  width: 13px;
  height: 2px;
  font-weight: normal;
}

/* TRUMPS, HELPERS AND OVERRIDES */
.u-focus {
  outline: solid 3px #ffb500;
}

.u-hidden {
  display: none;
}

.u-visuallyhidden {
  left: -9999em;
  outline: none;
  position: fixed;
}

.u-text-align-left {
  text-align: left;
}

.u-text-align-right {
  text-align: right;
}

.u-text-block-thin {
  display: block;
  font-weight: 400;
}

.u-text-inline-thin {
  font-weight: 400;
}

.u-text-size-display-1 {
  font-size: 1.33333em;
}

.u-text-size-display-2 {
  font-size: 1.55556em;
  line-height: 1.3;
}

.u-text-size-display-3 {
  font-size: 1.66667em;
  line-height: 1.2;
}

.u-text-size-display-4 {
  font-size: 1.77778em;
  line-height: 1.2;
}

.u-text-size-display-5 {
  font-size: 1.88889em;
  line-height: 1.1;
}

.u-text-size-display-6 {
  font-size: 2em;
  line-height: 1.2;
}

.u-text-weight-light {
  font-weight: 300;
}

.u-text-weight-regular {
  font-weight: 400;
}

.u-text-weight-bold {
  font-weight: 700;
}

.u-text-weight-semi-bold {
  font-weight: 600;
}

.u-display-inline {
  display: inline;
}

.u-text-block-italic {
  font-style: italic;
}

.u-padding-25 {
  padding-left: 25px;
  padding-right: 25px;
}

.u-layout-padding-none {
  padding-left: 0;
  padding-right: 0;
}

.u-margin-none {
  margin: 0;
}