﻿// Default Footer Styles

.layout__container--footer {
	border-top: 1px solid #eee;

	@media (min-width: 768px) {
		padding: 5px 0 0;
	}
}

.footer {
	clear: both;
	font-size: .875em;
	margin: 100px 0 0;
	min-height: 130px;
	overflow: hidden;

	&--scope a:hover {
		background-image: none;
	}

	&__content-one {
		float: left;
		min-height: 1px;
		position: relative;
		text-align: left;
		width: 100%;

		@media (min-width: 768px) {
			float: left;
			padding: 20px 20px 20px 0;
			width: 25%;
		}

		@media (min-width: 1200px) {
			padding: 10px 0;
		}
	}

	&__content-two {
		float: left;
		min-height: 1px;
		position: relative;
		width: 100%;

		@media (min-width: 768px) {
			float: left;
		}

		&--scope p {
			margin: 5px 0;
		}
	}

	&__content-three {
		float: left;
		min-height: 1px;
		position: relative;
		width: 100%;

		@media (min-width: 768px) {
			float: left;
			width: 25%;
		}
	}
}
