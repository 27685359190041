﻿// Default Grid Styles

.layout__controls {
	margin-top: 48px;

	&--info-content {
		margin-top: 24px;
	}
}

.layout__container {
	margin-left: auto;
	margin-right: auto;
	max-width: 100%;
	padding-left: 15px;
	padding-right: 15px;

	@media (min-width: 768px) {
		width: 750px;
	}

	@media (min-width: 992px) {
		width: 970px;
	}

	@media (min-width: 1200px) {
		width: 1170px;
	}

	&--fluid {
		margin-left: auto;
		margin-right: auto;
		padding-left: 15px;
		padding-right: 15px;
	}

	&--cookie-message {
		margin-left: auto;
		margin-right: auto;
		max-width: 100%;
		padding-left: 15px;
		padding-right: 15px;

		@media (min-width: 768px) {
			width: 750px;
		}

		@media (min-width: 992px) {
			width: 970px;
		}

		@media (min-width: 1200px) {
			width: 1170px;
		}
	}
}

.layout__row {
	margin-left: -15px;
	margin-right: -15px;

	&:after {
		clear: both;
		content: '';
		display: table;
	}
}

.layout__row-flex {
	display: flex;
	flex-wrap: wrap;
}

.layout__col--xs-1,
.layout__col--sm-1,
.layout__col--md-1,
.layout__col--lg-1,
.layout__col--xs-2,
.layout__col--sm-2,
.layout__col--md-2,
.layout__col--lg-2,
.layout__col--xs-3,
.layout__col--sm-3,
.layout__col--md-3,
.layout__col--lg-3,
.layout__col--xs-4,
.layout__col--sm-4,
.layout__col--md-4,
.layout__col--lg-4,
.layout__col--xs-5,
.layout__col--sm-5,
.layout__col--md-5,
.layout__col--lg-5,
.layout__col--xs-6,
.layout__col--sm-6,
.layout__col--md-6,
.layout__col--lg-6,
.layout__col--xs-7,
.layout__col--sm-7,
.layout__col--md-7,
.layout__col--lg-7,
.layout__col--xs-8,
.layout__col--sm-8,
.layout__col--md-8,
.layout__col--lg-8,
.layout__col--xs-9,
.layout__col--sm-9,
.layout__col--md-9,
.layout__col--lg-9,
.layout__col--xs-10,
.layout__col--sm-10,
.layout__col--md-10,
.layout__col--lg-10,
.layout__col--xs-11,
.layout__col--sm-11,
.layout__col--md-11,
.layout__col--lg-11,
.layout__col--xs-12,
.layout__col--sm-12,
.layout__col--md-12,
.layout__col--lg-12 {
	min-height: 1px;
	padding-left: 15px;
	padding-right: 15px;
	position: relative;

	&:after {
		content: '';
		clear: both;
		display: table;
	}
}

.layout__col--xs-1,
.layout__col--xs-2,
.layout__col--xs-3,
.layout__col--xs-4,
.layout__col--xs-5,
.layout__col--xs-6,
.layout__col--xs-7,
.layout__col--xs-8,
.layout__col--xs-9,
.layout__col--xs-10,
.layout__col--xs-11,
.layout__col--xs-12 {
	float: left;
}

.layout__col--xs-1 {
	width: 8.33333%;
}

.layout__col--xs-2 {
	width: 16.66667%;
}

.layout__col--xs-3 {
	width: 25%;
}

.layout__col--xs-4 {
	width: 33.33333%;
}

.layout__col--xs-5 {
	width: 41.66667%;
}

.layout__col--xs-6 {
	width: 50%;
}

.layout__col--xs-7 {
	width: 58.33333%;
}

.layout__col--xs-8 {
	width: 66.66667%;
}

.layout__col--xs-9 {
	width: 75%;
}

.layout__col--xs-10 {
	width: 83.33333%;
}

.layout__col--xs-11 {
	width: 91.66667%;
}

.layout__col--xs-12 {
	width: 100%;
}

.layout__col--xs-pull-0 {
	right: auto;
}

.layout__col--xs-pull-1 {
	right: 8.33333%;
}

.layout__col--xs-pull-2 {
	right: 16.66667%;
}

.layout__col--xs-pull-3 {
	right: 25%;
}

.layout__col--xs-pull-4 {
	right: 33.33333%;
}

.layout__col--xs-pull-5 {
	right: 41.66667%;
}

.layout__col--xs-pull-6 {
	right: 50%;
}

.layout__col--xs-pull-7 {
	right: 58.33333%;
}

.layout__col--xs-pull-8 {
	right: 66.66667%;
}

.layout__col--xs-pull-9 {
	right: 75%;
}

.layout__col--xs-pull-10 {
	right: 83.33333%;
}

.layout__col--xs-pull-11 {
	right: 91.66667%;
}

.layout__col--xs-pull-12 {
	right: 100%;
}

.layout__col--xs-push-0 {
	left: auto;
}

.layout__col--xs-push-1 {
	left: 8.33333%;
}

.layout__col--xs-push-2 {
	left: 16.66667%;
}

.layout__col--xs-push-3 {
	left: 25%;
}

.layout__col--xs-push-4 {
	left: 33.33333%;
}

.layout__col--xs-push-5 {
	left: 41.66667%;
}

.layout__col--xs-push-6 {
	left: 50%;
}

.layout__col--xs-push-7 {
	left: 58.33333%;
}

.layout__col--xs-push-8 {
	left: 66.66667%;
}

.layout__col--xs-push-9 {
	left: 75%;
}

.layout__col--xs-push-10 {
	left: 83.33333%;
}

.layout__col--xs-push-11 {
	left: 91.66667%;
}

.layout__col--xs-push-12 {
	left: 100%;
}

.layout__col--xs-offset-0 {
	margin-left: 0%;
}

.layout__col--xs-offset-1 {
	margin-left: 8.33333%;
}

.layout__col--xs-offset-2 {
	margin-left: 16.66667%;
}

.layout__col--xs-offset-3 {
	margin-left: 25%;
}

.layout__col--xs-offset-4 {
	margin-left: 33.33333%;
}

.layout__col--xs-offset-5 {
	margin-left: 41.66667%;
}

.layout__col--xs-offset-6 {
	margin-left: 50%;
}

.layout__col--xs-offset-7 {
	margin-left: 58.33333%;
}

.layout__col--xs-offset-8 {
	margin-left: 66.66667%;
}

.layout__col--xs-offset-9 {
	margin-left: 75%;
}

.layout__col--xs-offset-10 {
	margin-left: 83.33333%;
}

.layout__col--xs-offset-11 {
	margin-left: 91.66667%;
}

.layout__col--xs-offset-12 {
	margin-left: 100%;
}

@media (min-width: 768px) {

	.layout__col--sm-1,
	.layout__col--sm-2,
	.layout__col--sm-3,
	.layout__col--sm-4,
	.layout__col--sm-5,
	.layout__col--sm-6,
	.layout__col--sm-7,
	.layout__col--sm-8,
	.layout__col--sm-9,
	.layout__col--sm-10,
	.layout__col--sm-11,
	.layout__col--sm-12 {
		float: left;
	}

	.layout__col--sm-1 {
		width: 8.33333%;
	}

	.layout__col--sm-2 {
		width: 16.66667%;
	}

	.layout__col--sm-3 {
		width: 25%;
	}

	.layout__col--sm-4 {
		width: 33.33333%;
	}

	.layout__col--sm-5 {
		width: 41.66667%;
	}

	.layout__col--sm-6 {
		width: 50%;
	}

	.layout__col--sm-7 {
		width: 58.33333%;
	}

	.layout__col--sm-8 {
		width: 66.66667%;
	}

	.layout__col--sm-9 {
		width: 75%;
	}

	.layout__col--sm-10 {
		width: 83.33333%;
	}

	.layout__col--sm-11 {
		width: 91.66667%;
	}

	.layout__col--sm-12 {
		width: 100%;
	}

	.layout__col--sm-pull-0 {
		right: auto;
	}

	.layout__col--sm-pull-1 {
		right: 8.33333%;
	}

	.layout__col--sm-pull-2 {
		right: 16.66667%;
	}

	.layout__col--sm-pull-3 {
		right: 25%;
	}

	.layout__col--sm-pull-4 {
		right: 33.33333%;
	}

	.layout__col--sm-pull-5 {
		right: 41.66667%;
	}

	.layout__col--sm-pull-6 {
		right: 50%;
	}

	.layout__col--sm-pull-7 {
		right: 58.33333%;
	}

	.layout__col--sm-pull-8 {
		right: 66.66667%;
	}

	.layout__col--sm-pull-9 {
		right: 75%;
	}

	.layout__col--sm-pull-10 {
		right: 83.33333%;
	}

	.layout__col--sm-pull-11 {
		right: 91.66667%;
	}

	.layout__col--sm-pull-12 {
		right: 100%;
	}

	.layout__col--sm-push-0 {
		left: auto;
	}

	.layout__col--sm-push-1 {
		left: 8.33333%;
	}

	.layout__col--sm-push-2 {
		left: 16.66667%;
	}

	.layout__col--sm-push-3 {
		left: 25%;
	}

	.layout__col--sm-push-4 {
		left: 33.33333%;
	}

	.layout__col--sm-push-5 {
		left: 41.66667%;
	}

	.layout__col--sm-push-6 {
		left: 50%;
	}

	.layout__col--sm-push-7 {
		left: 58.33333%;
	}

	.layout__col--sm-push-8 {
		left: 66.66667%;
	}

	.layout__col--sm-push-9 {
		left: 75%;
	}

	.layout__col--sm-push-10 {
		left: 83.33333%;
	}

	.layout__col--sm-push-11 {
		left: 91.66667%;
	}

	.layout__col--sm-push-12 {
		left: 100%;
	}

	.layout__col--sm-offset-0 {
		margin-left: 0%;
	}

	.layout__col--sm-offset-1 {
		margin-left: 8.33333%;
	}

	.layout__col--sm-offset-2 {
		margin-left: 16.66667%;
	}

	.layout__col--sm-offset-3 {
		margin-left: 25%;
	}

	.layout__col--sm-offset-4 {
		margin-left: 33.33333%;
	}

	.layout__col--sm-offset-5 {
		margin-left: 41.66667%;
	}

	.layout__col--sm-offset-6 {
		margin-left: 50%;
	}

	.layout__col--sm-offset-7 {
		margin-left: 58.33333%;
	}

	.layout__col--sm-offset-8 {
		margin-left: 66.66667%;
	}

	.layout__col--sm-offset-9 {
		margin-left: 75%;
	}

	.layout__col--sm-offset-10 {
		margin-left: 83.33333%;
	}

	.layout__col--sm-offset-11 {
		margin-left: 91.66667%;
	}

	.layout__col--sm-offset-12 {
		margin-left: 100%;
	}
}

@media (min-width: 992px) {

	.layout__col--md-1,
	.layout__col--md-2,
	.layout__col--md-3,
	.layout__col--md-4,
	.layout__col--md-5,
	.layout__col--md-6,
	.layout__col--md-7,
	.layout__col--md-8,
	.layout__col--md-9,
	.layout__col--md-10,
	.layout__col--md-11,
	.layout__col--md-12 {
		float: left;
	}

	.layout__col--md-1 {
		width: 8.33333%;
	}

	.layout__col--md-2 {
		width: 16.66667%;
	}

	.layout__col--md-3 {
		width: 25%;
	}

	.layout__col--md-4 {
		width: 33.33333%;
	}

	.layout__col--md-5 {
		width: 41.66667%;
	}

	.layout__col--md-6 {
		width: 50%;
	}

	.layout__col--md-7 {
		width: 58.33333%;
	}

	.layout__col--md-8 {
		width: 66.66667%;
	}

	.layout__col--md-9 {
		width: 75%;
	}

	.layout__col--md-10 {
		width: 83.33333%;
	}

	.layout__col--md-11 {
		width: 91.66667%;
	}

	.layout__col--md-12 {
		width: 100%;
	}

	.layout__col--md-pull-0 {
		right: auto;
	}

	.layout__col--md-pull-1 {
		right: 8.33333%;
	}

	.layout__col--md-pull-2 {
		right: 16.66667%;
	}

	.layout__col--md-pull-3 {
		right: 25%;
	}

	.layout__col--md-pull-4 {
		right: 33.33333%;
	}

	.layout__col--md-pull-5 {
		right: 41.66667%;
	}

	.layout__col--md-pull-6 {
		right: 50%;
	}

	.layout__col--md-pull-7 {
		right: 58.33333%;
	}

	.layout__col--md-pull-8 {
		right: 66.66667%;
	}

	.layout__col--md-pull-9 {
		right: 75%;
	}

	.layout__col--md-pull-10 {
		right: 83.33333%;
	}

	.layout__col--md-pull-11 {
		right: 91.66667%;
	}

	.layout__col--md-pull-12 {
		right: 100%;
	}

	.layout__col--md-push-0 {
		left: auto;
	}

	.layout__col--md-push-1 {
		left: 8.33333%;
	}

	.layout__col--md-push-2 {
		left: 16.66667%;
	}

	.layout__col--md-push-3 {
		left: 25%;
	}

	.layout__col--md-push-4 {
		left: 33.33333%;
	}

	.layout__col--md-push-5 {
		left: 41.66667%;
	}

	.layout__col--md-push-6 {
		left: 50%;
	}

	.layout__col--md-push-7 {
		left: 58.33333%;
	}

	.layout__col--md-push-8 {
		left: 66.66667%;
	}

	.layout__col--md-push-9 {
		left: 75%;
	}

	.layout__col--md-push-10 {
		left: 83.33333%;
	}

	.layout__col--md-push-11 {
		left: 91.66667%;
	}

	.layout__col--md-push-12 {
		left: 100%;
	}

	.layout__col--md-offset-0 {
		margin-left: 0%;
	}

	.layout__col--md-offset-1 {
		margin-left: 8.33333%;
	}

	.layout__col--md-offset-2 {
		margin-left: 16.66667%;
	}

	.layout__col--md-offset-3 {
		margin-left: 25%;
	}

	.layout__col--md-offset-4 {
		margin-left: 33.33333%;
	}

	.layout__col--md-offset-5 {
		margin-left: 41.66667%;
	}

	.layout__col--md-offset-6 {
		margin-left: 50%;
	}

	.layout__col--md-offset-7 {
		margin-left: 58.33333%;
	}

	.layout__col--md-offset-8 {
		margin-left: 66.66667%;
	}

	.layout__col--md-offset-9 {
		margin-left: 75%;
	}

	.layout__col--md-offset-10 {
		margin-left: 83.33333%;
	}

	.layout__col--md-offset-11 {
		margin-left: 91.66667%;
	}

	.layout__col--md-offset-12 {
		margin-left: 100%;
	}
}

@media (min-width: 1200px) {

	.layout__col--lg-1,
	.layout__col--lg-2,
	.layout__col--lg-3,
	.layout__col--lg-4,
	.layout__col--lg-5,
	.layout__col--lg-6,
	.layout__col--lg-7,
	.layout__col--lg-8,
	.layout__col--lg-9,
	.layout__col--lg-10,
	.layout__col--lg-11,
	.layout__col--lg-12 {
		float: left;
	}

	.layout__col--lg-1 {
		width: 8.33333%;
	}

	.layout__col--lg-2 {
		width: 16.66667%;
	}

	.layout__col--lg-3 {
		width: 25%;
	}

	.layout__col--lg-4 {
		width: 33.33333%;
	}

	.layout__col--lg-5 {
		width: 41.66667%;
	}

	.layout__col--lg-6 {
		width: 50%;
	}

	.layout__col--lg-7 {
		width: 58.33333%;
	}

	.layout__col--lg-8 {
		width: 66.66667%;
	}

	.layout__col--lg-9 {
		width: 75%;
	}

	.layout__col--lg-10 {
		width: 83.33333%;
	}

	.layout__col--lg-11 {
		width: 91.66667%;
	}

	.layout__col--lg-12 {
		width: 100%;
	}

	.layout__col--lg-pull-0 {
		right: auto;
	}

	.layout__col--lg-pull-1 {
		right: 8.33333%;
	}

	.layout__col--lg-pull-2 {
		right: 16.66667%;
	}

	.layout__col--lg-pull-3 {
		right: 25%;
	}

	.layout__col--lg-pull-4 {
		right: 33.33333%;
	}

	.layout__col--lg-pull-5 {
		right: 41.66667%;
	}

	.layout__col--lg-pull-6 {
		right: 50%;
	}

	.layout__col--lg-pull-7 {
		right: 58.33333%;
	}

	.layout__col--lg-pull-8 {
		right: 66.66667%;
	}

	.layout__col--lg-pull-9 {
		right: 75%;
	}

	.layout__col--lg-pull-10 {
		right: 83.33333%;
	}

	.layout__col--lg-pull-11 {
		right: 91.66667%;
	}

	.layout__col--lg-pull-12 {
		right: 100%;
	}

	.layout__col--lg-push-0 {
		left: auto;
	}

	.layout__col--lg-push-1 {
		left: 8.33333%;
	}

	.layout__col--lg-push-2 {
		left: 16.66667%;
	}

	.layout__col--lg-push-3 {
		left: 25%;
	}

	.layout__col--lg-push-4 {
		left: 33.33333%;
	}

	.layout__col--lg-push-5 {
		left: 41.66667%;
	}

	.layout__col--lg-push-6 {
		left: 50%;
	}

	.layout__col--lg-push-7 {
		left: 58.33333%;
	}

	.layout__col--lg-push-8 {
		left: 66.66667%;
	}

	.layout__col--lg-push-9 {
		left: 75%;
	}

	.layout__col--lg-push-10 {
		left: 83.33333%;
	}

	.layout__col--lg-push-11 {
		left: 91.66667%;
	}

	.layout__col--lg-push-12 {
		left: 100%;
	}

	.layout__col--lg-offset-0 {
		margin-left: 0%;
	}

	.layout__col--lg-offset-1 {
		margin-left: 8.33333%;
	}

	.layout__col--lg-offset-2 {
		margin-left: 16.66667%;
	}

	.layout__col--lg-offset-3 {
		margin-left: 25%;
	}

	.layout__col--lg-offset-4 {
		margin-left: 33.33333%;
	}

	.layout__col--lg-offset-5 {
		margin-left: 41.66667%;
	}

	.layout__col--lg-offset-6 {
		margin-left: 50%;
	}

	.layout__col--lg-offset-7 {
		margin-left: 58.33333%;
	}

	.layout__col--lg-offset-8 {
		margin-left: 66.66667%;
	}

	.layout__col--lg-offset-9 {
		margin-left: 75%;
	}

	.layout__col--lg-offset-10 {
		margin-left: 83.33333%;
	}

	.layout__col--lg-offset-11 {
		margin-left: 91.66667%;
	}

	.layout__col--lg-offset-12 {
		margin-left: 100%;
	}
}

/* Print styling - makes cols print as md or lg percentages instead of xs size which is does as default */

@media print {

	.layout__col--print {
		float: left;
	}

	.layout__col--print.layout__col--md-8,
	.layout__col--print.layout__col--lg-8 {
		width: 66.66666667%;
	}

	.layout__col--print.layout__col--lg-7,
	.layout__col--print.layout__col--md-7 {
		width: 58.33333333%;
	}

	.layout__col--print.layout__col--lg-6,
	.layout__col--print.layout__col--md-6 {
		width: 50%;
	}

	.layout__col--print.layout__col--md-4.layout__col--lg-push-1,
	.layout__col--print.layout__col--lg-5,
	.layout__col--print.layout__col--md-5 {
		width: 41.66666667%;
	}

	.layout__col--print.layout__col--md-4,
	.layout__col--print.layout__col--md-4 {
		width: 33.33333333%;
	}

	.layout__col--print.layout__col--md-3,
	.layout__col--print.layout__col--lg-3 {
		width: 25%;
	}

	.layout__col--print.layout__col--md-2,
	.layout__col--print.layout__col--lg-2 {
		width: 16.66666667%;
	}

	.layout__col--print.layout__col--lg-1,
	.layout__col--print.layout__col--md-1 {
		width: 8.33333333%;
	}
}

@media (min-width: 768px) {

	.layout__flex {
		display: flex;
		justify-content: space-between;
		flex-direction: column;
	}

	.layout__flex--item {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 100%;
		flex-wrap: wrap;
	}

	.layout__flex--item a {
		width: 48%;
	}
}
