@media print {
	body {
		background: #fff;
		-webkit-print-color-adjust: exact;
		print-color-adjust: exact;
	}
}

.print-together {
	break-inside: avoid-page;
}
