﻿// Default Card Styles

.cfp-card {
	background: $card-cfp-color-background;
	color: $card-cfp-color-text;
	padding: 1.2rem 2rem;
	margin-top: 1.2em;
	border-radius: $btn-border-radius;

	&:hover {
		background-color: $card-cfp-color-background-hover;
	}

	&__heading {
		font-weight: bold;
		font-size: 1.125em;
		margin-bottom: .5rem;
	}

	&__detail {
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;

		&__item {
			flex: 1 1 auto;
		}

		&--authority {
			font-weight: 600;
		}

		&--status {
			font-weight: 600;
		}

		&--action {
			color: $card-cfp-color-text-action;
			text-align: right;
		}
	}
	&-link {
		display: block;
	}
}

.unread-counter {
	color: $card-doc-color-unread-indicator;
	font-weight: 600;
}

ul.doc-card-list {
	li {
		background: $card-doc-color-background;
		color: $card-doc-color-text;
		margin: 1em 0;
		padding: 1.2rem 2rem;
		border: $card-doc-color-border solid 1px;
		border-radius: $btn-border-radius;

		.title {
			margin-bottom: 8px;
			display: flex;
			justify-content: flex-start;
			flex-wrap: wrap;

			.title-content {
				flex: 10 1 auto;
			}

			.doc-title {
				font-size: 20px;
				font-weight: 600;

				.name-title {
					font-weight: normal;
				}
			}

			.unread {
				@extend .unread-counter;
				flex: 1 1 auto;
				text-align: right;
			}
		}

		.doc-at,
		.doc-date,
		.doc-on-behalf-of {
			font-weight: 600;
		}

		.actions {
			margin-top: 1.2rem;

			a {
				margin-right: 1.2rem;
			}
		}
	}
}

.card {
	background: $card-cfp-color-background;
	padding: .625rem;
	font-weight: 400;
	font-size: 1.125em;
	margin-top: .75rem;
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;

	@media (max-width: 992px) {
		flex-wrap: wrap;
	}

	&.cfp-index-list {
		@media (min-width: 992px) {
			max-width: 600px;
		}
	}

	&--warning {
		background: #fff4da;
	}

	&__item {
		flex: 1 1 auto;
		line-height: 1.6;
		word-wrap: break-word;
	}

	&__item--icon {
		width: 20px;
		flex: 0;
		margin-right: 10px;

		@media screen\0 {
			margin-right: 27px;
		}

		@media (max-width: 992px) {
			display: none;
		}
	}

	&__item--data {
		flex: 1;
	}

	&__item--status {
		text-align: right;
	}

	&__item--controls {
		text-align: right;
	}

	&__link {
		display: block;
		text-shadow: none;
	}

	&__link .icon__container {
		width: 14px;
		height: 14px;
	}

	&__item-status {
		text-transform: uppercase;
	}

	&__item-status--warning {
		color: #ad112a;
	}

	&__item-split {
		margin-right: 1.5rem;
		display: inline-block;
	}

	&__item-split:last-of-type {
		margin-right: 0;
	}

	&__item-split--smallcaps {
		text-transform: uppercase;
		font-size: .875rem;
	}

	&--link {
		background: transparent;
		border: 1px #125397 solid;
		color: #125397;
		cursor: pointer;

		&:hover,
		&:active,
		&:focus {
			outline: 1px #125397 solid;
		}
	}

	&__item--icon,
	&__item--status,
	&__item--info,
	&__item--controls {
		@media (max-width: 992px) {
			flex: 1 1 100%;
			text-align: left;
		}
	}
}
