﻿// Default Validatable Item Styles

.validatable-item {
	border: 0;
	margin-top: 1.5em;

	&--additional {
		margin-top: .75em;
	}

	&.is-added {
		background-color: transparent;
	}

	&--danger {
		position: relative;

		&:before {
			border-left: 2px solid #ad112a;
			bottom: 0;
			content: '';
			left: -1.25em;
			position: absolute;
			top: 0;

			@media (max-width: 768px) {
				left: -10px;
			}
		}
	}

	&__title {
		font-size: 1.25em;
		font-weight: 400;
		line-height: 1.2em;
		width: 100%;
	}

	&__title--group {
		font-weight: 600;
	}

	&__overview {
		display: block;
		margin-top: .5em;
	}

	&__footer {
		display: block;
		margin-top: .65em;
	}

	&__example-text {
		display: block;
		font-size: 1.125em;
		font-weight: 300;
		padding-top: 10px;
	}

	&__additional-information {
		border-left: 3px solid #000;
  		margin: 0.75em 0 0;
  		padding: 0 0 0 0.75em;
	}
}
