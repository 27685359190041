﻿// Default Info Content Styles

$note-color: #000;

.info-content {
	display: block;
	margin: 3em 0 0;

	&--first {
		margin: 0;
	}

	&__sub-title {
		font-size: 2em;

		&--emphasis {
			font-weight: 600;
		}
	}

	&__result {
		display: block;
		font-size: 4.5em;
		font-weight: 600;
	}

	&__sub-info {
		padding-top: 1em;
	}

	&__intro-text {
		font-size: 18px;
		font-weight: 600;

		@media (min-width: 992px) {
			font-size: 18px;
		}

		.cfp-index-section & {
			margin-top: 2rem;
		}
	}

	&__title-second {
		font-weight: normal;
	}

	&__button {
		background-color: transparent;
		background-image: linear-gradient(#4175ac, #4175ac);
		background-position: 0 100%;
		background-repeat: repeat-x;
		background-size: 1px 1px;
		border: 0;
		color: #4175ac;
		cursor: pointer;
		font-size: 1rem;
		text-decoration: none;
		text-shadow: .04545em 0 0 #fff, .09091em 0 0 #fff, .13636em 0 0 #fff, -.04545em 0 0 #fff, -.09091em 0 0 #fff, -.13636em 0 0 #fff, 0 .04545em 0 #fff, 0 .09091em 0 #fff, 0 -.04545em 0 #fff, 0 -.09091em 0 #fff;

		&:hover {
			background-image: none;
			text-decoration: none;
		}
	}

	&__link {
		text-decoration: underline;

		&:active,
		&:focus,
		&:hover {
			text-decoration: none;
		}

		.info-content__cfp-actions & {
			padding: .5em 0;
		}
	}

	&__cfp-actions {
		display: flex;
		flex-direction: column;
	}

	&__note {
		border: 1px solid;
		font-size: 1.125em;
		margin-top: 1.5em;
		margin-bottom: 24px;
		padding: 15px 15px 15px 50px;
		position: relative;

		p {
			font-size: 1em;
			margin: 0 0 8px;

			&:last-child {
				margin: 0;
			}
		}

		@media (max-width: 991px) {
			font-size: 1em;
		}
	}

	&__note-inner {
		background: $note-color;
		left: 0;
		padding: 10px;
		position: fixed;
		right: 0;
		top: 0;
		z-index: 1;
	}

	&__note-title {
		font-size: 1.55556em;
		font-weight: 600;

		&:first-of-type {
			margin-top: 0;
		}
	}

	&__note--danger {
		background: #ad112a;
		color: #fff;

		.info-content__link {
			color: #fff;
			text-decoration: underline;

			&:active,
			&:hover {
				text-decoration: none;
			}
		}

		.icon__container {
			fill: #fff;
		}
	}

	&--no-margin {
		margin: 0;
	}

	&__note--nomargin {
		margin: 0;
	}

	&__note--top-message {
		background: $note-color;
		border: 0;
		color: #fff;
		min-height: 44px;

		.info-content__link {
			color: #fff;
			text-decoration: underline;

			&:active,
			&:hover {
				text-decoration: none;
			}
		}
	}

	&__icon {
		position: absolute;
		left: 12px;
		top: 16px;
	}

	&__attention-content {
		font-weight: 600;
	}
}

.hub-page .info-content__title {
	margin-top: 37px;
}
