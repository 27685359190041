﻿// Default Info Button Styles

.info-button {
	border-radius: $btn-border-radius;
	background: $btn-color-background;
	color: $white;
	display: block;
	margin-bottom: 1.5em;
	margin-top: 1.5em;
	padding: 1.25em 1.25em 1.5em;
	position: relative;
	text-shadow: none;

	&:active,
	&:focus,
	&:hover {
		background-image: none;
		background: $btn-color-background-hover;
		color: #fff;
		border: 0;

		&:after {
			display: none;
		}
	}

	&__note-title {
		margin-top: 0;
	}

	&__note-title,
	&__attention-content {
		font-weight: 600;
	}
}

.document-button {
	background: linear-gradient(-3deg, transparent 66%, darken($btn-color-document-top-background, 10%) 66.5%) no-repeat border-box, linear-gradient(0deg, $white 60%, $btn-color-document-top-background 54%) no-repeat border-box;
	color: $black;
	padding-top: 2%;
	border: 2px solid $grey-95;
	border-radius: 12px;

	h2 {
		margin: .5em 0 2em;
		padding-bottom: 1em;
		font-size: 1.2em;
		color: $white;
		font-weight: 400;
	}

	p {
		font-size: 1em;
	}

	.info-button__icon-container {
		fill: $black;
	}

	&:active,
	&:hover,
	&:focus {
		border: 2px solid $black;
		border-color: $grey-95;
		background: linear-gradient(-3deg, transparent 66%, $btn-color-document-top-background 66.5%) no-repeat border-box, linear-gradient(0deg, $white 60%, darken($btn-color-document-top-background, 10%) 54%) no-repeat border-box;
		color: $occ-blue;

		.info-button__icon-container {
			fill: $occ-blue;
		}
	}
}


.hub-buttons {
	@media (min-width: 768px) {
		display: flex;
	}

	&__wrapper {

		@media (min-width: 768px) {
			display: flex;
		}
	}

	.info-button {
		width: 100%;
	}
}
