﻿// Default Utility Styles

/* TRUMPS, HELPERS AND OVERRIDES */
.u-focus {
	outline: solid 3px #ffb500;
}

.u-hidden {
	display: none;
}

.u-visuallyhidden {
	left: -9999em;
	outline: none;
	position: fixed;
}

.u-text-align-left {
	text-align: left;
}

.u-text-align-right {
	text-align: right;
}

.u-text-block-thin {
	display: block;
	font-weight: 400;
}

.u-text-inline-thin {
	font-weight: 400;
}

.u-text-size-display-1 {
	font-size: 1.33333em;
}

.u-text-size-display-2 {
	font-size: 1.55556em;
	line-height: 1.3;
}

.u-text-size-display-3 {
	font-size: 1.66667em;
	line-height: 1.2;
}

.u-text-size-display-4 {
	font-size: 1.77778em;
	line-height: 1.2;
}

.u-text-size-display-5 {
	font-size: 1.88889em;
	line-height: 1.1;
}

.u-text-size-display-6 {
	font-size: 2em;
	line-height: 1.2;
}

.u-text-weight-light {
	font-weight: 300;
}

.u-text-weight-regular {
	font-weight: 400;
}

.u-text-weight-bold {
	font-weight: 700;
}

.u-text-weight-semi-bold {
	font-weight: 600;
}

.u-display-inline {
	display: inline;
}

.u-text-block-italic {
	font-style: italic;
}

.u-padding-25 {
	padding-left: 25px;
	padding-right: 25px;
}

.u-layout-padding-none {
	padding-left: 0;
	padding-right: 0;
}

.u-margin-none {
	margin: 0;
}
