﻿// Default Navigation Styles

.nav {
	display: inline-block;

	&__collapse-button {
		background: none;
		background-image: none;
		text-decoration: none;
		text-shadow: none;

		@media (max-width: 768px) {
			border: 0;
			display: block;
		}

		@media (min-width: 768px) {
			display: none;
		}

		&-title {
			color: #fff;
			display: block;
			float: left;
			font-size: 1.11111em;
			line-height: 35px;
			text-transform: uppercase;
		}
	}

	&__cb-layers-container {
		border: 3px solid #fff;
		border-radius: 3px;
		display: block;
		height: 35px;
		margin-left: 65px;
		width: 35px;

		&--xl {
			margin-left: 70px;
		}

		&--xll {
			margin-left: 74px;
		}
	}

	&__cb-layer {
		background: #fff;
		display: block;
		height: 3px;
		margin: 5px;
	}

	&__colapse-container {
		display: block;

		@media (max-width: 768px) {
			display: none;
		}

		@media (min-width: 768px) {
			display: block;
		}

		&.is-collapsed {
			display: block;
		}
	}

	&--header {
		min-height: 1px;
		padding: 24px 0;
		position: relative;

		@media (min-width: 768px) {
			float: left;
			width: 25%;
		}

		@media (max-width: 767px) {
			float: right;
			padding: 10px 0;
		}

		@media (min-width: 768px) {
			left: 33.33333%;
		}

		.nav__list-items {

			@media (min-width: 767px) {
				float: right;
			}
		}

		.nav__item {

			@media (min-width: 768px) {
				margin: 0 0 0 20px;
			}

			@media (min-width: 768px) {
				float: left;
			}
		}

		.nav__link {
			background-image: none;
			color: #fff;
			font-size: .875em;
			text-decoration: none;
			text-shadow: none;

			@media (max-width: 991px) {
				display: inline-table;
			}

			&:hover {
				color: #fff;
				background-image: linear-gradient(#fff, #fff);
				background-position: 0 1.15em;
				background-size: 1px 1px;
				background-repeat: repeat-x;
			}
		}
	}

	&--footer {
		float: left;
		min-height: 1px;
		padding: 10px 0;
		position: relative;
		width: 100%;

		@media (min-width: 768px) {
			float: left;
			width: 75%;
		}

		.nav__item {
			display: inline-block;
			margin-right: 31px;

			&:last-child {
				margin-right: 0;
			}
		}

		.nav__link {
			background-image: none;
			position: relative;
			text-decoration: none;
			text-shadow: none;

			&:active,
			&:focus,
			&:hover {
				text-decoration: underline;
			}
		}
	}
}
