﻿// Default Table Styles

table {
	border-spacing: 0;
}

.table {
	margin-bottom: 15px;
	overflow-y: hidden;

	@media (max-width: 750px) {
		padding-bottom: 15px;
	}

	&--margin-top {
		margin-top: 24px;
	}

	&__data-table {
		border: transparent;
		width: 100%;
	}

	&__header-cell,
	&__footer-cell {
		text-align: left;
	}

	&__footer-cell {
		vertical-align: text-top;
	}

	&__header-cell,
	&__cell {
		border-bottom: 1px solid #d4d4d4;
	}

	&__footer-row--divider-bottom .table__footer-cell,
	&__row--divider-bottom .table__cell {
		border-bottom: 1px solid #7e7e7e;
	}

	&__footer-row--divider-top .table__footer-cell,
	&__row--divider-top .table__cell {
		border-top: 1px solid #7e7e7e;
	}

	&__header-cell,
	&__footer-cell,
	&__cell {
		padding: 10px 5px 10px 0;
	}

	&__cell--span:nth-child(2) {
		padding-top: 5px;
	}

	&__summary-text {
		display: block;
		font-style: italic;
		font-weight: 300;
	}

	&__row--bold:last-child {

		td {
			border-bottom: 2px solid #7e7e7e;
		}

		th {
			border-bottom: 2px solid #7e7e7e;
		}
	}

	&__border-bottom-add {

		th,
		td {
			border-bottom: 2px solid #7e7e7e;
		}
	}

	&__border-bottom:last-child {

		th {
			border-bottom-color: #7e7e7e;
		}

		td {
			border-bottom-color: #7e7e7e;
		}
	}

	&__border-collapse {
		border-collapse: collapse;
	}

	&__border-bottom-hide {

		th {
			border-bottom: 0;
		}

		td {
			border-bottom: 0;
		}
	}

	&__cell-display {
		display: table-cell;
	}

	&__highlighted {
		background: #ebebeb;

		td {
			border-bottom: 0;
		}

		&:not(.table__highlighted-print) td {

			&:first-child {
				padding-left: 15px;
			}

			&:last-child {
				padding-right: 15px;
			}
		}
	}

	&__margin-none {
		margin-bottom: 0;
	}

	&__row-hide-last-border:last-child td {
		border-bottom: 0;
	}

	.table__fixed {

		td {
			padding-right: 10px;
		}

		th {
			padding-right: 10px;
		}
	}

	.table__row--highlighted {
		background: #ebebeb;
	}
}

.u-table-border-width {

	td {
		border-width: 2px;
	}

	th {
		border-width: 2px;
	}

	tr:last-child td {
		border-width: 2px;
	}
}

.u-table-border-top {

	th {
		border-top: 1px solid #000;
	}

	td {
		border-top: 1px solid #000;
	}
}

div.table.table-indented:not(.table__highlighted) table tr:not(.table__row--highlighted) td:first-child:before,
div.table.table-indented:not(.table__highlighted) table tr:not(.table__row--highlighted) th:first-child:before {
	background: #f8f8f8;
	bottom: -3px;
	content: '';
	height: 5px;
	left: 0;
	position: absolute;
	width: 10px;
}

div.table.table-indented:not(.table__highlighted) table tr:not(.table__row--highlighted) td {
	position: relative;
}

div.table.table-indented:not(.table__highlighted) table tr:not(.table__row--highlighted) td:last-child:after,
div.table.table-indented:not(.table__highlighted) table tr:not(.table__row--highlighted) th:last-child:after {
	bottom: -3px;
	background: #f8f8f8;
	content: '';
	height: 5px;
	position: absolute;
	right: 0;
	width: 6px;
}

.download__pbs {

	div.table.table-indented:not(.table__highlighted) table tr:not(.table__row--highlighted) td:first-child:before,
	div.table.table-indented:not(.table__highlighted) table tr:not(.table__row--highlighted) th:first-child:before {
		background: #fff;
		bottom: -3px;
		content: '';
		height: 5px;
		left: -2px;
		position: absolute;
		width: 10px;
	}

	div.table.table-indented:not(.table__highlighted) table tr:not(.table__row--highlighted) td {
		position: relative;
	}

	div.table.table-indented:not(.table__highlighted) table tr:not(.table__row--highlighted) td:last-child:after,
	div.table.table-indented:not(.table__highlighted) table tr:not(.table__row--highlighted) th:last-child:after {
		background: #fff;
		bottom: -3px;
		content: '';
		height: 5px;
		position: absolute;
		right: 0;
		width: 6px;
	}
}

.table__padding tr td:first-child,
.table__padding tr th:first-child {
	padding-left: 15px;
}

.table__padding tr td:last-child,
.table__padding tr th:last-child {
	padding-right: 15px;
}

.table__row-padding-bottom td,
.table__row-padding-bottom th {
	padding-bottom: 2em;
}

@media screen and (max-width: 500px) {

	.table__row-flex {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;

		td {
			width: 100%;

			&.table__cell--borderless {
				border-bottom: 0;
			}
		}
	}

	.table:not(.table__highlighted) .table__row-flex:last-child td:first-child {
		border-top: 2px solid #d4d4d4;
	}

	.table__highlighted td {
		padding-left: 15px;
		padding-right: 15px;
	}
}

@media all and (max-width: 768px) {
	.table__cell--10,
	.table__cell--15,
	.table__cell--20,
	.table__cell--25,
	.table__cell--30,
	.table__cell--35,
	.table__cell--40,
	.table__cell--45,
	.table__cell--60,
	.table__cell--70 {
		padding-right: 1em;
		width: 120px;
	}
}

@media all and (min-width: 500px) {

	.table__cell-25 {
		width: 30%;
	}

	.table:not(.table__highlighted) .table__row-flex:last-child td {
		border-top: 2px solid #d4d4d4;
	}
}

@media all and (min-width: 768px) {

	.table__cell--10 {
		width: 10%;
	}

	.table__cell--15 {
		width: 15%;
	}

	.table__cell--20 {
		width: 20%;
	}

	.table__cell--25 {
		width: 25%;
	}

	.table__highlighted {

		.table__cell--25 {
			width: 25.5%;
		}

		.table__cell--30 {
			width: 30.5%;
		}
	}

	.table__cell--30 {
		width: 30%;
	}

	.table__cell--35 {
		width: 35%;
	}

	.table__cell--40 {
		width: 40%;
	}

	.table__cell--45 {
		width: 45%;
	}

	.table__cell--60 {
		width: 60%;
	}

	.table__cell--70 {
		width: 70%;
	}

	.table__border-bottom:last-child {

		th {
			border-bottom: 0;
		}

		td {
			border-bottom: 0;
		}
	}

	.layout__table_flex {
		display: flex;
		justify-content: space-between;

		.table__flex {
			display: flex;
			flex-wrap: wrap;
			width: 48%;

			h3 {
				padding-bottom: 15px;
				width: 100%;
			}

			table {
				display: flex;
				flex-direction: column;
				height: calc(100% - 5em);

				tbody {
					height: 100%;
				}

				tr {
					width: 100%;

					td {
						width: 100%;
					}

					th {
						width: 100%;
					}
				}
			}
		}
	}

	.table__border-bottom-hide--md {

		th {
			border-bottom: 0;
		}

		td {
			border-bottom: 0;
		}
	}
}

@media all and (min-width: 850px) {

	.table__fixed {
		table-layout: fixed;
	}
}

@media all {

	.print-white {
		background: #fff;
		-webkit-print-color-adjust: exact;
		print-color-adjust: exact;
	}

	.print-break {
		background: #fff;
		page-break-before: always;
	}
}

.avoid_pagebreak {
	page-break-inside: avoid;
}

.grid-table {
	border: solid 1px #000;
	border-collapse: collapse;
	border-spacing: 0;
	font: normal 13px Arial, sans-serif;
}

.grid-table thead th {
	background-color: #000;
	border: solid 1px #000;
	color: #fff;
	padding: 10px;
	text-align: left;
}

.grid-table tbody td {
	border: solid 1px #000;
	color: #333;
	padding: 10px;
}

.nowrap {
	white-space: nowrap;
}
