﻿// Default Typography Styles

p {
	font-size: 1em;
	margin: 12px 0 0;
	line-height: 1.5;

	@media (min-width: 992px) {
		font-size: 1.125em;
	}
}

*[data-bind*="customWordingHtml:"] p {
	font-size: 1em;
}

input,
select.input,
label,
td,
th,
textarea {
	font-size: 1em;

	@media (min-width: 992px) {
		font-size: 1.125rem;
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	line-height: 1.2em;
}

h1 {
	font-size: 2em;
	margin-top: .75rem;

	@media (min-width: 992px) {
		font-size: 2.25em;
	}
}

h2 {
	font-size: 1.5em;
	margin-top: 1.5rem;

	@media (min-width: 992px) {
		font-size: 1.75em;
	}

	&.h2-smaller {
		font-size: 1.375em;
		margin-top: 2.25rem;

		@media (min-width: 992px) {
			font-size: 1.5em;
		}
	}
}

h3 {
	font-size: 1.375em;
	margin-top: 2.25rem;

	@media (min-width: 992px) {
		font-size: 1.5em;
	}
}

h4 {
	font-size: 1.25em;
	margin-top: 1.5rem;
}

h5 {
	font-size: 1.125em;
	margin-top: 1.5rem;
}

h6 {
	font-size: 1rem;
	margin-top: 1.5em;
}

a {
	color: $link-color-text;
	cursor: pointer;
	font-size: 1.125em;
	text-decoration: underline;

	@media (max-width: 991px) {
		font-size: 1em;
	}

	&:active,
	&:hover,
	&:focus {
		background-image: none;
		color: $link-color-text-hover;
		text-decoration: underline;
	}

	&::selection {
		background: #e0e0e0;
		text-shadow: none;
	}
}

a.disabled {
	pointer-events: none;
	cursor: default;
	text-decoration: none;
	color: $link-color-text-disabled !important;
}

p a,
span {
	font-size: inherit;
}

.u-text-weight-regular {

	p {
		font-size: inherit;
	}
}
