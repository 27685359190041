﻿// Default Validatable Item Group Styles

.validatable-item-group {
	border-left: 1px solid #c8c8c8;
	margin-bottom: 24px;
	margin-left: -24px;
	padding-left: 24px;

	@media (max-width: 767px) {
		margin-left: 0;
	}
}
