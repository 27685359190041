﻿// Default More Info Styles

.more-info {
	display: block;

	&--padding {
		padding: 1.2em 0;
	}

	&__link-icon .icon__container {
		fill: $occ-blue;
	}

	&__link--span {
		cursor: pointer;
		color: #fff;
		background-color: none;

		&:hover,
		&:active {
			color: #ccc;
		}
	}


	&__item {
		display: inline-block;

		.more-info__link {
			margin: 0 .75em 0 0;
		}

		&:last-of-type {
			margin-right: 0;
		}
	}

	&__tab-pane {
		border-left: 3px solid #000;
		display: none;
		margin: .75em 0 0;
		padding: 0 0 0 .75em;

		&--scope p {
			margin: 0 0 .75rem;
		}

		&.is-active {
			display: block;
		}

		&:focus {
			outline: 0;
		}
	}

	&__list {
		padding: 0 0 1em 1em;
	}

	&__list--ordered {
		list-style-type: decimal;
	}

	&__subheading {
		font-size: 1em;
		margin-top: 16px;
	}

	&__paragraph {
		margin-top: 4px;
	}
}

.calc-table {

	.more-info__link {
		font-size: 1em;
	}

	.more-info__tab-pane p  {
		font-size: 1em;
	}
}
