﻿// Default Alert Styles

.alert {
	background: #fff;
	border: 3px solid #333;
	display: none;
	left: 25%;
	padding: 20px;
	position: fixed;
	top: 4%;
	z-index: 9999999;
}
