﻿// Default Breadcrumb Styles

.breadcrumbs {
	color: $breadcrumb-color-text;
	display: block;
	margin: 24px 0 0;

	a {
		text-decoration: underline;
	}
}
