﻿// Default Upload Box

.upload-box {
	background-color: #fff;
	border: 1px solid #000;
	font-size: 1.25rem;
	margin-bottom: 14px;
	padding: 50px 20px;
	position: relative;

	&--has-advanced-upload {
		outline: 0;
		outline-offset: -10px;
		transition: outline-offset .15s ease-in-out, background-color .15s linear;
	}

	&--is-dragover {
		background-color: #f3f6fa;
	}

	&__dragndrop,
	&__icon {
		display: none;
	}

	&__dragndrop {
		display: inline;
	}

	&__icon {
		display: block;
		fill: #92b0b3;
		height: 80px;
		margin-bottom: 40px;
		width: 100%;
	}

	&__input {
		text-align: center;
	}

	&--is-error .upload-box__input,
	&--is-success .upload-box__input,
	&--is-uploading .upload-box__input {
		visibility: hidden;
	}

	&__error,
	&__success,
	&__uploading {
		display: none;
	}

	&__error {
		color: #ad112a;
	}

	&--is-error .upload-box__error,
	&--is-success .upload-box__success,
	&--is-uploading .upload-box__uploading {
		display: block;
		left: 0;
		padding: 0 20px;
		position: absolute;
		right: 0;
		text-align: center;
		top: 50%;
		transform: translateY(-50%);
	}

	&__uploading {
		font-style: italic;
	}

	&__success {
		animation: appear-from-inside .25s ease-in-out;
	}

	&__button {
		display: none;

		&:focus,
		&:hover {
			background-color: #0f3c4b;
		}
	}

	&__restart {
		font-weight: 700;
	}

	&__ipload-icon {
		display: block;
		margin: 0 auto;
		width: 100px;

		.icon__container {
			height: 100px;
			width: 100px;
		}
	}

	&__file-label {
		font-weight: 300;
	}

	&__file-label-action {
		color: #125397;
		text-decoration: underline;
		text-underline-offset: 3px;
		cursor: pointer;
		font-weight: 400;

		&:hover,
		&:active {
			color: #125397;
			text-decoration: underline;
		}
	}
}

@keyframes appear-from-inside {

	from {
		transform: translateY(-50%) scale(0);
	}

	75% {
		transform: translateY(-50%) scale(1.1);
	}

	to {
		transform: translateY(-50%) scale(1);
	}
}

.js .upload-box__file {
	height: .1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	width: .1px;
	z-index: -1;

	+ label {
		cursor: pointer;
		display: inline-block;
		max-width: 80%;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	&.has-focus + label,
	&:focus + label {
		outline: 1px dotted #000;
		outline: -webkit-focus-ring-color auto 5px;
	}
}

.no-js .upload-box__file + label {
	display: none;
}

.no-js .upload-box__button {
	display: block;
}
