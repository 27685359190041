﻿// Default Collapse Menu Styles

.collapse-menu {
	margin-top: 2.25em;

	&__container:after,
	&__title:after {
		background: #c8c8c8;
		content: '';
		display: block;
		height: 1px;
		margin-bottom: 1rem;
		margin-top: 1rem;
		width: 100%;
	}

	&__section-content {
		margin-bottom: 1.5em;
		overflow: auto;
	}

	&__section-title {
		margin-bottom: .625em;
	}

	&__button {
		background: none;
		border: 0;
		cursor: pointer;
		text-align: left;

		@media (min-width: 991px) {
			font-size: 1.125em;
		}

		&:active,
		&:focus,
		&:hover {
			text-decoration: underline;
		}
	}
}
