// Default CFP navigation menu styles

.cfp-nav-menu {
	a {
		display: block;
		margin: .75rem 0;
	}

	&__section {
		margin-bottom: 2.5rem;
	}
}
