﻿// Default List Styles

ul,
ol {
	list-style: none;
	margin: 0;
}

ul ul,
ul ol,
ol ul,
ol ol {
	margin-left: 12px;
}

li > ul,
li > ol {
	list-style: none;
	margin-bottom: 0;
}

/**
 * Visually offset definition titles from their definitions.
 */
dt {
	font-weight: 700;
}

.list {
	margin-top: 12px;
	margin-bottom: 24px;
	padding-left: 20px;

	&__item {
		margin-top: 6px;
		list-style: disc;
	}

	&--bottom-margin-zero {
		margin-top: 12px;
		margin-bottom: 0;
	}
}
