﻿// Default Summary Panel Styles

.summary-panel {
	background: transparent;
	border: 1px solid #000;
	width: 100%;

	&:not(.summary-panel--cfp) {

		@media (min-width: 992px) {
			width: 295px;
		}

		@media (min-width: 1200px) {
			width: 360px;
		}
	}

	@media (max-width: 992px) {
		margin: 1.5em 0 0;
	}

	&.is-sticky {
		top: 1.5em;

		@media (min-width: 992px) {
			width: 295px;
		}

		@media (min-width: 1200px) {
			width: 360px;
		}

		@media (min-width: 992px) {
			position: fixed;
			z-index: 9999;
		}

		@media screen and (max-device-width: 480px) {
			position: relative;
		}
	}

	&.is-full-screen {
		bottom: 0;
		overflow-x: auto;
		top: 0;
	}

	&__header {
		border-bottom: 1px solid #000;
		padding: 6px 20px 5px;
	}

	&__header-title {
		font-size: 2em;
		font-weight: 700;
		line-height: 1.5;
		margin-top: 0;
	}

	&__header-expand-link {
		background-position: 0 1.55em;
		display: none;
		float: right;
		padding-top: 6px 0 0;
	}

	&__body {
		min-height: 300px;
		padding: 5px 20px 20px;

		&.is-expanded {
			height: 100%;
		}
	}

	&__footer {
		padding: 5px 20px 20px;
	}

	&__btn {
		margin: 0 0 0 4px;
	}

	&__calculations {
		padding: 5px 20px;
	}

	&__summary {
		display: inline-block;
		font-size: 1em;
		font-weight: 600;
		line-height: 1;
		width: 140px;
	}

	&__result {
		font-size: 1.83333em;
		font-weight: 600;
		line-height: 1.1;
	}

	&__message {
		font-size: 1em;
		font-weight: 600;
		line-height: 1;
	}

	&__cfp {
		background: none;
		border-color: #979797;
		margin-top: 10px;
		padding-bottom: 12px;
	}

	&__cfp-title {
		font-size: .9em;
		font-weight: 600;
	}

	&__cfp-info {
		font-size: .9em;
	}

	&__cfp-item {

		@media screen and (min-width: 400px) {
			align-items: center;
			display: flex;
			justify-content: space-between;
		}
	}

	&__cfp-item p {

		@media screen and (min-width: 400px) {
			width: 45%;
		}
	}
}

.print-white .summary-panel {
	background: #fff;
}
