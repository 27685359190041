﻿// Default Form Styles
$checkbox-border-radius: 4px;

.form-element {
	display: block;
	margin-top: .375em;

	&--inline {
		display: inline-block;
		margin-top: 0;
		background: transparent;
	}

	&--single,
	&--data-short,
	&--data-long {
		padding-left: 0;
	}

	&--single .form-element__sign,
	&--data-short .form-element__sign,
	&--data-long .form-element__sign {
		margin-left: 0;
	}

	&--data-long .form-element__input,
	&--data-short .form-element__input {
		width: 100%;
	}

	&--data-short .form-element__input {
		margin: 0 .75em 0 0;
		max-width: 52px;
	}

	&--data-long .form-element__input {
		max-width: 70px;
	}

	&:first-of-type {
		margin-left: 0;
	}

	&__label {
		display: block;
		margin-right: 6px;

		&:only-child {
			margin-right: 0;
		}
	}

	&__label--last {
		margin-right: 0;
	}

	&__label--validatable-item-select,
	&__label--fullwidth {
		display: inline-block;
	}

	&__input--select-type {
		margin-bottom: 8px;
	}

	&__label--select,
	&__label--validatable-item-select,
	&__label--fullwidth {
		position: relative;

		&:after {
			background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #fff 93%, #fff 100%);
			display: block;
			height: 47px;
			position: absolute;
			right: 28px;
			top: 2px;
			width: 39px;
			z-index: 1060;
		}
	}

	&__label--validatable-item-select {

		@media (max-width: 992px) {
			margin: 8px 6px 0 0;
		}

		@media (max-width: 768px) {
			margin: 8px 6px 0 0;
		}

		@media (max-width: 480px) {
			margin: 8px 6px 8px 0;
		}
	}

	&__label--fullwidth {
		width: 100%;
	}

	&__label--checkbox {
		border-radius: $checkbox-border-radius;
	}

	&__label--radio {
		border: 1px solid #000;
		display: inline-block;
		line-height: normal;
		padding: 13px 15px;

		@media (max-width: 992px) {
			margin-left: 0;
		}

		@media (max-width: 480px) {
			margin-left: 0;
		}
	}

	&__label--select,
	&__label--validatable-item-select,
	&__label--validatable-item-select-block {
		display: block;
		background: transparent;
	}

	&__label--checkbox,
	&__label--radio {
		background: #fff;
	}

	&__label--disabled {
		border-color: #767676;
		color: #767676;
	}

	&--readonly .form-element__sign--labelover {
		color: #767676;
	}

	&--readonly .form-element__input {
		border-color: #767676;
		color: #767676;
	}

	&--disabled .form-element__sign--labelover {
		color: #767676;
	}

	&--disabled .form-element__input {
		border-color: #767676;
		color: #767676;
	}

	&__checkbox,
	&__radio {
		margin-right: 10px;
	}

	&__input {
		-webkit-appearance: none;
		-moz-appearance: textfield;
		border: 1px solid #000;
		border-radius: 0;
		line-height: normal;
		padding: 13px;
	}

	&--danger .form-element__input {
		outline: 2px solid #ad112a;

		&:focus {
			outline: 2px solid #ffb500;
		}
	}

	&__input--xxl {
		min-width: 100px;

		@media (min-width: 992px) {
			margin: 0 6px 0 0;
			width: calc(100% - 293px);
		}

		@media (max-width: 992px) {
			margin: 0 6px 0 0;
			width: calc(100% - 415px);
		}

		@media (max-width: 768px) {
			margin: 0 6px 6px 0;
			width: calc(100% - 40px);
		}

		@media (max-width: 480px) {
			margin: 0 6px 6px 0;
			width: calc(100% - 10px);
		}
	}

	&__input--xl {
		min-width: 100px;

		@media (min-width: 992px) {
			margin: 0 6px 0 0;
			width: calc(100% - 390px);
		}

		@media (max-width: 992px) {
			margin: 0 6px 0 0;
			width: calc(100% - 390px);
		}

		@media (max-width: 768px) {
			margin: 0 6px 6px 0;
			width: calc(100% - 484px);
		}

		@media (max-width: 480px) {
			margin: 0 6px 6px 0;
			width: calc(100% - 10px);
		}
	}

	&__input--unit {
		min-width: 100px;

		@media (min-width: 992px) {
			margin: 0 6px 0 0;
			width: 155px;
		}

		@media (max-width: 992px) {
			margin: 0 6px 0 0;
			width: 120px;
		}

		@media (max-width: 768px) {
			margin: 0 6px 0 0;
			width: 120px;
		}

		@media (max-width: 480px) {
			margin: 0 6px 6px 0;
			width: 120px;
		}
	}

	&__input--l {
		min-width: 100px;

		@media (min-width: 992px) {
			margin: 0 6px 0 0;
			width: calc(100% - 418px);
		}

		@media (max-width: 992px) {
			margin: 0 6px 0 0;
			width: calc(100% - 498px);
		}

		@media (max-width: 768px) {
			margin: 0 6px 6px 0;
			width: calc(100% - 484px);
		}

		@media (max-width: 480px) {
			margin: 0 6px 6px 0;
			width: calc(100% - 10px);
		}
	}

	&__input--m {
		min-width: 100px;

		@media (min-width: 992px) {
			margin: 0 6px 0 0;
			width: calc(100% - 510px);
		}

		@media (max-width: 992px) {
			margin: 0 6px 0 0;
			width: calc(100% - 506px);
		}

		@media (max-width: 768px) {
			margin: 0 6px 6px 0;
			width: calc(100% - 70px);
		}

		@media (max-width: 480px) {
			margin: 0 6px 6px 0;
			width: calc(100% - 10px);
		}
	}

	&__sign {
		font-size: 1.11111em;
		font-weight: 600;
		padding: 0 10px;

		@media (max-width: 480px) {
			margin: 0;
		}
	}

	&__sign--label {
		display: block;
		font-size: 1em;
		font-weight: 300;
		padding: 6px 0 4px;

		@media (max-width: 768px) {
			font-size: .8889em;
		}
	}

	&__labelover-container {
		display: inline;
		position: relative;

		.form-element__input {
			padding-left: 2em;
		}
	}

	&__sign--labelover {
		position: absolute;
		top: -3px;
	}

	&__input--select {
		-webkit-appearance: none;
		-moz-appearance: none;
		border: 1px solid #000;
		background: $white url('../../images/icon-caret-down.svg') no-repeat right 11px top 50%;
		background-size: 20px 20px;
		border-radius: 0;
		line-height: normal;
		padding: 13px 40px 13px 11px;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: auto;
	}

	&__input--full-width {
		width: 100%;
	}

	&__input--select::-ms-expand {
		display: none;
	}

	&__input--textarea {
		resize: none;
		width: 100%;
	}

	&__input--single-l {
		min-width: 100px;


		@media (min-width: 992px) {
			margin: 0 6px 0 0;
			width: calc(100% - 310px);
		}

		@media (max-width: 992px) {
			margin: 0 6px 0 0;
			width: calc(100% - 440px);
		}

		@media (max-width: 768px) {
			margin: 0 6px 0 0;
			width: calc(100% - 200px);
		}

		@media (max-width: 480px) {
			margin: 0 6px 0 0;
			width: calc(100% - 0px);
		}
	}

	&__input--single-xl {
		min-width: 100px;

		@media (min-width: 992px) {
			margin: 0 6px 0 0;
			width: calc(100% - 240px);
		}

		@media (max-width: 992px) {
			margin: 0 6px 0 0;
			width: calc(100% - 340px);
		}

		@media (max-width: 768px) {
			margin: 0 6px 0 0;
			width: calc(100% - 150px);
		}

		@media (max-width: 480px) {
			margin: 0 6px 0 0;
			width: calc(100% - 0px);
		}
	}

	&__label.form-element__label--radio {

		@media (max-width: 480px) {
			margin: 0 6px 6px 0;
		}
	}

	&__label.form-element__label--radio-inline-block {
		display: inline-block;
	}
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
