﻿// Default Calculation Table Styles

.calc-table {
	width: 100%;

	&__divider,
	&__summary {
		text-align: left;
	}

	&__summary {
		padding-top: 6px;

		@media (min-width: 992px) {
			padding-top: 0;
		}
	}

	&__result {
		text-align: right;
	}

	&__divider,
	&__summary,
	&__result {
		padding-top: 12px;
	}

	&__divider {
		border-bottom: 1px solid #000;
		padding-bottom: 24px;
	}

	&__link {
		color: $occ-dark-blue;
		font-size: 1.125em;
	}
}

.is-active {

	.calc-table__link {
		font-weight: 700;
	}

	.calc-table__result {
		font-weight: 700;
		color: $occ-dark-blue;
	}
}
