﻿// Default Accessibility Styles

.accessibility__skip-menu {
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;

	&-link {
		left: -9999px;
		position: absolute;

		&:active,
		&:focus {
			background: #ffb500;
			color: #000;
			display: block;
			left: 0;
			padding: .25em 1em;
			position: static;
			text-shadow: none;
		}
	}
}

.accessibility__item {
	margin-left: 111px;
	margin: 0;
	position: absolute;
	z-index: 1;
}

.accessibility__page-nav-text {
	left: -9999px;
	position: absolute;
}

.accessibility-text-size {
	color: #fff;
	display: inline-block;
	float: right;
	margin-right: .625em;

	@media (min-width: 750px) {
		margin-top: -2px;
	}

	&__content {
		font-size: 12px;
		vertical-align: middle;
	}

	&__link {
		background-color: #000;
		color: #fff;
		cursor: pointer;
		line-height: 15px;
		padding: 0 4px;
		vertical-align: middle;
		text-align: center;
		text-shadow: none;
	}

	&__link--small {
		font-size: 12px;
		line-height: inherit;
		padding: 4px 6px;
	}

	&__link--medium {
		font-size: 14px;
		line-height: inherit;
		padding: 3px 6px;
	}

	&__link--large {
		font-size: 15px;
		line-height: inherit;
		padding: 3px 6px;
	}
}
