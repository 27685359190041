﻿// Default Layout Styles

.layout__container:after {
	clear: both;
}

.layout__container:after,
.layout__container:before {
	content: ' ';
	display: table;
}

.layout__container {
	&--cookie-message {
		padding: 24px 0 16px;

		input[type='button'] {
			margin-right: 16px;

			@media (max-width: 425px) {
				margin-bottom: 16px;
			}
		}
	}
}

img.document_logo--devdemo {
	background-color: #fff;
	border: 3px solid #000;
	left: 0;
	padding: 2px;
	position: relative;
	top: 0;
}

.cfp-landing {
	&__heading {
		margin-top: 36px;
	}

	&__subheading {
		margin-top: 24px;
	}

	.more-info {
		margin-top: 8px;
	}
}

.cfp-page-content {
	@media (min-width: 992px) {
		padding-right: 10%;
	}

	h1 {
		margin-bottom: 1.7em;
	}

	h2 {
		margin-top: 1.7em;
	}

	&__doc-list {
		h2 {
			margin-top: 1.7em;
		}
	}
}

.counter {
	display: flex;
	justify-content: flex-end;
	flex-wrap: wrap;

	&__spacer {
		flex: 10 1 auto;
	}

	&__item {
		flex: 1 1 auto;
		text-align: right;
		//padding: 0 .75em;
		padding: 0 2rem;
	}
}


.document {
	iframe.document__isolated-container {
		border: 0;
		width: 100%;
	}
}
