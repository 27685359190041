﻿// Default Timeline Styles

.timeline {
	font-size: 1em;
	padding-top: 10px;
	padding-bottom: 15px;

	&__link {
		background-image: linear-gradient(#125397, #125397);
		color: #125397;
		margin-right: 6px;

		&:first-of-type {
			margin-right: 12px;
		}
	}

	&__list,
	&__item {
		display: inline-block;
	}

	&__list {
		border-top: 1px solid #000;
		margin-left: 10px;

		@media (max-width: 1200px) {
			border-top: 0;
		}
	}

	&__item {
		margin-left: 6px;
		padding: 0;

		@media (max-width: 1200px) {
			display: none;
		}

		&:first-of-type {
			margin-left: 0;
		}

		&:last-of-type .timeline__nav-link {
			margin-right: -1px;
		}
	}

	&__nav-link {
		background: none;
		color: #d3d3d3;
		display: block;
		line-height: initial;
		margin-right: 6px;
		padding-top: 5px;
		position: relative;
		text-shadow: none;
	}
}

.is-active.timeline__item {

	@media (max-width: 1200px) {
		display: block;
	}
}

.is-visited .timeline__nav-link {
	color: #000;
}

.timeline__nav-link:active,
.timeline__nav-link:hover,
.is-active .timeline__nav-link {
	color: #125397;

	&:before {
		background: #125397;
		content: '';
		display: block;
		height: 3px;
		position: absolute;
		top: 0;
		width: 100%;

		@media (max-width: 1200px) {
			display: none;
		}
	}
}
