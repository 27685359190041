﻿// Default Header Styles

.header {

	&__banner {
		background-color: $header-color-background;
		margin: 0;
		overflow: hidden;
		-webkit-print-color-adjust: exact;
		print-color-adjust: exact;
	}

	&__brand {
		color: $header-color-text;
		font-size: 1.5em;
		min-height: 1px;
		padding: 1.5rem 0;
		position: relative;

		@media (min-width: 768px) {
			float: left;
			width: 41.66667%;
		}

		@media (max-width: 991px) {
			display: inline-block;
			padding: 10px 0;
		}
	}

	&__logo {
		display: block;
		float: none;
		margin: 0;
		max-width: 100%;
	}

	&__content {
		color: #fff;
		min-height: 1px;
		padding: 1.5em 0;
		position: relative;

		@media (min-width: 768px) {
			float: left;
			width: 33.33333%;
		}
	}

	&__widgets {
		clear: both;
		float: left;
		min-height: 1px;
		padding: 0 0 1.5em;
		position: relative;
		width: 100%;
	}
}

.print-white .header {
	background: #fff;
}

.portal-header {
	background: $header-color-background;
	color: $header-color-text;

	&__container {
		margin-left: auto;
		margin-right: auto;
		max-width: 100%;
		padding-left: 15px;
		padding-right: 15px;

		@media (min-width: 768px) {
			width: 750px;
		}

		@media (min-width: 992px) {
			width: 970px;
		}

		@media (min-width: 1200px) {
			width: 1170px;
		}

		&--content {
			font-size: 1.2em;
			min-height: 1px;
			padding: 1.2rem 0;
		}
	}
}
