﻿// Default Button Styles
$btn-border-radius: 4px;
$btn-border-width: 1px;

.button {
	border: 0;
	border-radius: $btn-border-radius;
	cursor: pointer;
	display: inline-block;
	font-size: 1em;
	font-weight: 400;
	margin: 0;
	padding: .75rem 1.125rem;
	position: relative;
	text-decoration: none;
	text-shadow: none;

	@media (min-width: 992px) {
		font-size: 1.125em;
	}

	&__icon {
		padding-left: .5em;

		&__container {
			width: 24px;
			height: 24px;
			vertical-align: sub;
		}
	}

	&.active,
	&:active,
	&:hover {
		background-image: none;
	}

	&.active:after,
	&:active:after,
	&:hover:after {
		display: none;
	}

	&:first-child {
		margin-left: 0;
	}

	&--no-margin {
		margin-left: 0;
		margin-right: 0;
	}

	&--action {
		background-color: $btn-color-background;
		color: $btn-color-text;
		border: $btn-border-width solid $btn-color-background;

		&:active,
		&:hover,
		&:focus {
			background-color: $btn-color-background-hover;
			color: $btn-color-text;
			border: $btn-border-width solid $btn-color-background-hover;
		}
	}

	&--secondary {
		background: $btn-color-background-alt;
		color: $btn-color-text-alt;
		border: $btn-border-width solid $btn-color-background;

		&:active,
		&:hover,
		&:focus {
			background-color: $btn-color-background-hover;
			color: $btn-color-text;
			border: $btn-border-width solid $btn-color-background-hover;
		}
	}

	&__cookie-banner {
		color: $btn-color-text;
	}

	&--small {
		font-size: 1.125rem;
	}
}

.button:disabled,
.button.is-disabled,
.button[disabled],
fieldset[disabled] .button {
	cursor: not-allowed;
	background: #d4d4d4;
}

.layout__button-group {

	@media (max-width: 500px) {
		align-items: flex-start;
		display: flex;
		flex-direction: column;

		a {
			margin-right: 0;
			margin-top: 0;
		}
	}

	@media (max-width: 992px) {
		margin-top: 2em;
	}

	button,
	a {
		margin-bottom: 20px;
		margin-right: 30px;
		margin-top: 3em;

		@media (max-width: 992px) {
			margin-top: 0;
			margin-right: 10px;
		}

		@media (max-width: 500px) {
			width: 100%;
			text-align: center;
		}

		&:last-child {
			margin-right: 0;
		}
	}
}

a.button {
	&,
	&:hover,
	&:focus,
	&:active {
		text-decoration: none;
	}
}
