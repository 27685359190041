﻿// Default Base Styles

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

html {
	background: #f8f8f8;
	color: #000;
	font-family: 'Source Sans Pro', sans-serif;
	font-size: 100%;
	line-height: 1.5;
	position: relative;
	min-height: 100%;
}

body {
	height: 100%;

}

img {
	max-width: 100%;
	vertical-align: middle;
}

input,
textarea,
select,
button {
	color: inherit;
	font: inherit;
	margin: 0;
}

fieldset
{
	border-style: none;
}

.layout__central-background {
	display: flex;
	flex-direction: column;
	min-height: 100vh;

	section:not(.portal-header) {
		flex-grow: 1;
	}
}

input:focus,
textarea:focus,
select:focus,
.button:focus,
.form-element__label:focus,
.nav__collapse-button:focus,
label.is-active,
a:focus {
	outline: 3px solid #ffb500;
}

input[type='checkbox'],
input[type='radio'] {
	outline: 0;
}

label.is-selected {
	border: 1px solid #871a67;
}
