﻿// Default Modal Styles

.modal {
	background: #fff;
	border: 3px solid #333;
	left: 25%;
	padding: 20px;
	position: fixed;
	top: -400px;
	z-index: 9999999;

	&.in {
		top: 4%;
	}

	&-cover {
		background: rgba(255, 255, 255, .8);
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
		position: absolute;
		overflow: hidden;
		z-index: 9999;
	}
}
