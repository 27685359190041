﻿// Default Summary Table Styles

.summary-table {
	margin-bottom: 15px;
	overflow-y: hidden;

	@media (max-width: 750px) {
		padding-bottom: 15px;
	}

	&--margin-top {
		margin-top: 24px;
	}

	&__data-table {
		border: 1px solid #ddd;
		width: 100%;
	}

	&__header-cell {
		text-align: left;
	}

	&__row {
		background-color: #f9fafb;
	}

	&__row--summary {
		display: none;
	}

	&__row--dark {
		background-color: #ebeced;
	}

	&__row--collapse {
		display: none;
	}

	&__row--collapse-in {
		display: block;
	}

	&__header-cell,
	&__cell {
		border: 1px solid #fff;
		padding: 2px 10px;
	}

	&__row--darker,
	&__header {
		background-color: #6d7879;
		color: #fff;
	}

	&__col-1 {
		min-width: 100px;
	}

	&__col-2 {
		min-width: 200px;
	}

	&__col-3 {
		min-width: 200px;
	}

	&__cell--summary {
		padding: 15px;
	}

	&__info-link {
		background-image: linear-gradient(#fff, #fff);
		color: #fff;
		text-shadow: .04545em 0 0 #6d7879,
		.09091em 0 0 #6d7879,
		.13636em 0 0 #6d7879,
		-.04545em 0 0 #6d7879,
		-.09091em 0 0 #6d7879,
		-.13636em 0 0 #6d7879,
		0 .04545em 0 #6d7879,
		0 .09091em 0 #6d7879,
		0 -.04545em 0 #6d7879,
		0 -.09091em 0 #6d7879;

		&:focus,
		&:active,
		&:hover {
			color: #fff;
		}
	}

	&__link-icon {

		.icon__container {
			fill: #fff;
		}

		&:after {
			background: #6d7879;
		}
	}

	&--light {

		.summary-table__data-table {
			border: transparent;
		}

		.summary-table__row--darker,
		.summary-table__header {
			background-color: transparent;
			color: #000;
		}

		.summary-table__header-cell,
		.summary-table__cell {
			border: 0;
			padding: 2px 0;
		}

		.summary-table__row {
			background-color: transparent;
			border-bottom: 1px solid #ddd;
		}
	}
}
