﻿// Default Validation Message Styles

.validation-message {
	color: #ad112a;
	display: block;
	font-size: 1.125em;
	font-weight: 600;
	margin-top: 12px;

	&--error {
		position: relative;

		&:before {
			border-left: 2px solid #ad112a;
			bottom: 0;
			content: '';
			left: -20px;
			position: absolute;
			top: 0;

			@media (max-width: 768px) {
				left: -10px;
			}
		}

		.validatable-item__link {
			color: #ad112a;
		}
	}
}

.field-validation-error {
	color: #c70a0a;
}
