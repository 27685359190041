﻿// Default Icon Styles

svg:not(:root) {
	overflow: hidden;
}

.icon {
	display: inline-block;
	line-height: initial;
	transition: transform .3s ease;
	fill: $occ-blue;

	&__container--rotate-180,
	&--rotate-180 {
		transform: rotate(180deg);
	}

	&--link {
		background-image: none;
		position: relative;
		text-decoration: none;
		z-index: 999;

		&:after {
			content: '';
			position: absolute;
			z-index: -1;
			display: block;
			background: #f8f8f8;
			height: 3px;
			bottom: 0;
			left: 0;
			width: 21px;

			@media screen and (min-width: 0\0) {
				bottom: 3px;
			}
		}
	}

	&__source,
	&__container {
		width: 20px;
		height: 20px;
		vertical-align: sub;

		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			vertical-align: text-bottom;
		}
	}

	&--big .icon__source,
	&--big .icon__container {
		width: 26px;
		height: 26px;
		vertical-align: bottom;
	}

	&__assistive-text {
		position: absolute;
		margin: -1px;
		border: 0;
		padding: 0;
		width: 1px;
		height: 1px;
		overflow: hidden;
		clip: rect(0 0 0 0);
	}

	&__container {
	}

	&--left {
		float: left;
	}

	&__container--card-link {
	}

	&--summary-table {

		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			vertical-align: 4%;
		}
	}
}

_::-webkit-:not(:root:root),
.icon--link:after {
	bottom: 1px;
}

.more-info__link:focus .icon--link:after {
	display: none;
}
