.app-step-nav {
	margin-bottom: 30px;

	&--disabled {
		pointer-events: none;
	}
}


.app-step-nav__controls {
	padding: 3px 3px 0 0;
	text-align: right;
}

.js-step-controls-button {
	margin-top: 10px;
}

.app-step-nav__button {
	color: #1d70b8;
	cursor: pointer;
	background: none;
	border: 0;
	margin: 0;

	&:focus,
	&:focus-visible {
		outline: 3px solid #ffb500;
	}
}


.app-step-nav__button--title {
	font-size: 19px;
	font-weight: bold;
	line-height: 1.4;
	display: inline-block;
	padding: 0;
	text-align: left;
	color: #0b0c0c;
}

.app-step-nav__button--controls {
	font-size: 14px;
	font-weight: normal;
	line-height: 1;
	text-decoration: underline;
	text-decoration-thickness: max(1px, .0625rem);
	text-underline-offset: .1em;
	position: relative;
	padding: .5em 0;
}

.app-step-nav__button--controls:hover {
	text-decoration-thickness: max(3px, .1875rem, .12em);
	text-decoration-skip: none;
}


.app-step-nav__steps {
	padding: 0;
	margin: 0;
}

.app-step-nav__step {
	position: relative;
	padding-left: 45px;
	list-style: none;
}

.app-step-nav__step:after {
	content: '';
	position: absolute;
	z-index: 2;
	width: 0;
	height: 100%;
	border-left: solid 2px #b1b4b6;
	background: #fff;
	left: 0;
	margin-left: 12px;
	top: 15px;
}


.app-step-nav__step--active .app-step-nav__circle--number,
.app-step-nav__step--active:after {
	border-color: #0b0c0c;
}

.app-step-nav__circle {
	box-sizing: border-box;
	position: absolute;
	z-index: 5;
	top: 15px;
	left: 0;
	width: 26px;
	height: 26px;
	color: #0b0c0c;
	background: #fff;
	border-radius: 100px;
	text-align: center;
}

.app-step-nav__circle--number {
	font-size: 16px;
	font-weight: bold;
	line-height: 23px;
	border: solid 2px #b1b4b6;
}

.app-step-nav__circle--logic {
	font-size: 16px;
	font-weight: bold;
	line-height: 28px;
}

.app-step-nav__circle-inner {
	float: right;
	min-width: 100%;
}

.app-step-nav__circle-background {
	text-shadow: 0 -.1em 0 #fff, .1em 0 0 #fff, 0 .1em 0 #fff, -.1em 0 0 #fff;
}

.app-step-nav__circle-step-label,
.app-step-nav__circle-step-colon {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: 0;
	padding: 0;
	overflow: hidden;
	clip: rect(0 0 0 0);
	clip-path: inset(50%);
	border: 0;
	white-space: nowrap;
}

.app-step-nav_main-header {
	text-align: left;
}

.app-step-nav__header {
	padding: 15px 0;
	border-top: solid 2px #b1b4b6;
}

.app-step-nav--active .app-step-nav__header {
	cursor: pointer;
}

.app-step-nav__header:hover .app-step-nav__circle {
	color: #1d70b8;
}

.app-step-nav__button:hover .app-step-nav__button--header {
	text-decoration: underline;
	text-decoration-thickness: max(1px, .0625rem);
	text-underline-offset: .1em;
	text-decoration-skip: none;
}

.app-step-nav__button:hover .app-step-nav__button--header:hover {
	text-decoration: underline;
	text-decoration-thickness: max(1px, .0625rem);
	text-underline-offset: .1em;
	text-decoration-skip: none;
}

.app-step-nav__button:hover .app-step-nav__toggle-link {
	text-decoration: underline;
	text-decoration-thickness: max(3px, .1875rem, .12em);
	text-underline-offset: .1em;
	text-decoration-skip: none;
}

.app-step-nav__button:hover .app-step-nav__toggle-link:hover {
	text-decoration-thickness: max(3px, .1875rem, .12em);
	text-underline-offset: .1em;
	text-decoration-skip: none;
}

.app-step-nav__title {
	color: #0b0c0c;
	font-size: 19px;
	font-weight: bold;
	line-height: 1.4;
	margin: 0;
}

.app-step-nav__toggle-link {
	font-size: 14px;
	font-weight: normal;
	line-height: 1.2;
	display: block;
	color: #1d70b8;
	text-transform: capitalize;
}

.app-step-nav__panel {
	color: #0b0c0c;
	font-size: 16px;
	font-weight: normal;
	line-height: 1.3;

	&--collapsed {
		display: none;
	}
}


.app-step-nav__list {
	padding: 0;
	padding-bottom: 10px;
	list-style: none;
}

.app-step-nav__list-item {
	font-size: 1.125em;
}

.app-step-nav__link {
	text-decoration: underline;
	text-decoration-thickness: max(1px, .0625rem);
	text-underline-offset: .1em;
}


.app-step-nav__link:hover {
	text-decoration-thickness: max(3px, .1875rem, .12em);
	text-decoration-skip: none;
}

.app-step-nav__link-active-context {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: 0;
	padding: 0;
	overflow: hidden;
	clip: rect(0 0 0 0);
	clip-path: inset(50%);
	border: 0;
	white-space: nowrap;
}

.app-step-nav__list-item--active {
	position: relative;
	font-weight: normal;
}

.app-step-nav__list-item--active:before {
	box-sizing: border-box;
	border-top: 3px solid black;
	content: '';
	position: absolute;
	z-index: 5;
	top: .6em;
	left: -45px;
	margin-top: -1px;
	margin-left: 13px;
	width: 13px;
	height: 2px;
	font-weight: normal;
}
